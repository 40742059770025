<template>
    <div>
        <div class="container mx-auto overflow-y-auto h-[100vh] p-4 lg:p-0">
            <div class="my-10 lg:flex items-center justify-between">
                <img v-if="dossier.magasin && dossier.magasin.centrale" class="h-[40px] mb-8 lg:hidden" :src="dossier.magasin.centrale.logo" alt="">
                <div>
                    <h2 class="text-4xl font-bold uppercase">Suivi de votre dossier SAV</h2>
                    <h2 class="font-light uppercase mt-2">Référence dossier: {{ dossier.num_dsc }}</h2>
                </div>
                <img v-if="dossier.magasin && dossier.magasin.centrale" class="h-[50px] hidden lg:block" :src="dossier.magasin.centrale.logo" alt="">
            </div>
            <div class="lg:grid lg:grid-cols-8 gap-4">
                <div class="col-span-2">
                    <div class="mb-4 bg-slate-100 shadow border p-4 rounded">
                        <h2 class="text-xl font-semibold">Dossier</h2>
                        <div class="font-light text-sm mt-2 pt-2 border-t">Référence: {{ dossier.num_dsc }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t">Appareil: {{ dossier.appareil['Marque'] + ' ' + dossier.appareil['Modele'] }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t">Date achat: {{ dossier.date_achat | momentDate }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t">Date demande: {{ dossier.date_demande | momentDate }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t">Description: {{ dossier.descript }}</div>
                    </div>
                    <div class="mb-4 bg-slate-100 shadow border p-4 rounded">
                        <h2 class="text-xl font-semibold">Documents</h2>
                        <div v-for="(doc, docIndex) in dossier.documents" class="font-light text-sm mt-2 pt-2 border-t" :key="'doc-' + docIndex">
                            <a class="text-blue-600 hover:underline" target="_blank" :href="doc.url"><i class="fas fa-link mr-0.5"></i> {{ doc.type_doc }}</a>
                        </div>
                    </div>
                </div>
                <div class="col-span-4">
                    <div class="mb-4 bg-slate-100 shadow border p-4 rounded">
                        <h2 class="text-xl font-semibold">Suivi du dossier</h2>
                        <div class="mt-4 pt-4 border-t">
                            <ol class="relative border-l border-gray-200">                  
                                <li v-for="(avancement, avancementIndex) in dossier.avancements" :key="'avancement-' + avancementIndex" class="mb-10 ml-4">
                                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                                    <time class="mb-1 text-sm font-normal leading-none text-slate-600">{{ avancement.date_av | momentDateTime }}</time>
                                    <h3 class="text-lg" :class="avancementIndex == dossier.avancements.length - 1 ? 'text-blue-600 font-bold' : 'text-gray-900'">{{ avancement.libelle_av}}</h3>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="col-span-2">
                    <div v-if="dossier.magasin" class="mb-4 bg-slate-100 shadow border p-4 rounded">
                        <h2 class="text-xl font-semibold">Magasin</h2>
                        <div class="font-light text-sm mt-2 pt-2 border-t"><i class="fas fa-building mr-0.5"></i> {{ dossier.magasin.magasin }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t"><i class="fas fa-location-dot mr-0.5"></i> {{ dossier.magasin.adresse1 + ' ' + dossier.magasin.adresse2 + ' ' + dossier.magasin.cp + ' ' + dossier.magasin.ville }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t" v-if="dossier.magasin.mailant"><i class="fas fa-at mr-0.5"></i> {{ dossier.magasin.mailant }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t" v-if="dossier.magasin.telmag"><i class="fas fa-phone mr-0.5"></i> {{ dossier.magasin.telmag }}</div>
                    </div>
                    <div v-if="dossier.client_final.id != 0" class="mb-4 bg-slate-100 p-4 rounded">
                        <h2 class="text-xl font-semibold">Client</h2>
                        <div class="font-light text-sm mt-2 pt-2 border-t">{{ dossier.client_final.prenom + ' ' + dossier.client_final.nom }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t">{{ dossier.client_final.mail }}</div>
                        <div class="font-light text-sm mt-2 pt-2 border-t">{{ dossier.client_final.tel }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            dossier: null
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getDossier() {
            let response = await this.$request.post('suivi.dossier', {
                num_dsc: this.$route.params.ref
            })
            this.dossier = response.data.posts.post
        }
    },
    mounted () {
        this.getDossier();
    },
}
</script>

<style lang="scss" scoped>

</style>