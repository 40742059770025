<template>
    <div>
        <modal name="modal-tech-devis" width="95%" height="auto" :scrollable="true" :click-to-close="false">
            <div>
                <div class="flex items-center justify-between bg-blue-100 px-4 py-2">
                    <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Facturation') }}</div>
                </div>
                <div class="p-4 flex gap-4 items-center justify-center" v-if="loadingGetData">
                    <spinner :size="10" />
                    <div class="font-light text-sm">{{$tt('Chargement du dossier')}}..</div>
                </div>
                <div class="p-4" v-if="!loadingGetData && data">
                    <div class="flex mt-2 gap-4">
                        <div class="w-[500px]">
                            <div class="bg-blue-200 p-4 rounded mb-2">
                                <h2 class="text-xs font-bold border-b border-gray-500 pb-2 mb-2"><i class="fas fa-folder mr-1"></i> {{ $tt('Dossier') }}</h2>
                                <div class="grid grid-cols-2 gap-2">
                                    <!-- Dossier -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Dossier') }}:</div>
                                        <div class="font-bold">{{ data.ref_dossier }}</div>
                                        <button @click="emitInfoFolder" class="text-sm text-slate-600 px-2.5 py-1.5 rounded"><i class="fas fa-circle-info"></i></button>
                                    </div>
                                    <!-- Marque -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Marque') }}:</div>
                                        <div class="font-bold">{{ data.device['Marque'] }}</div>
                                    </div>
                                    <!-- Catégorie -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Catégorie') }}:</div>
                                        <div class="font-bold">{{ data.categ ? data.categ.libelle : $tt('Aucune') }}</div>
                                    </div>
                                    <!-- Num serie -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Numéro de série') }}:</div>
                                        <div class="font-bold">{{ data.num_serie }}</div>
                                    </div>
                                    <!-- Ref commerciale -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Référence commerciale') }}:</div>
                                        <div class="font-bold">{{ data.device.ref_commerciale }}</div>
                                    </div>
                                    <!-- Ref commerciale -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Modèle / Vers') }}:</div>
                                        <div class="font-bold">{{ data.device['Modele'] }}</div>
                                    </div>
                                    <div class="col-span-2">
                                        <div class="text-xs font-bold">{{ $tt('Message interne') }}</div>
                                        <div class="text-xs bg-blue-100 border-0 p-2 rounded mt-1">
                                            {{ data.message_interne || '-' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-blue-200 p-4 rounded mb-2">
                                <h2 class="text-xs font-bold border-b border-gray-500 pb-2 mb-2"><i class="fas fa-user mr-1"></i> {{ $tt('Client') }}</h2>
                                <div class="grid grid-cols-1 gap-2">
                                    <!-- Nom client -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt("Client Intervention / Donneur d'ordre") }}:</div>
                                        <div class="font-bold">{{ data.end_customer.nom + ' ' + data.end_customer.prenom }} / {{$tt('Magasin')}}</div>
                                    </div>
                                    <!-- Tel -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Téléphone') }}:</div>
                                        <div class="font-bold">{{ data.end_customer.gsm || '-' }} <span v-if="data.end_customer.gsm && data.end_customer.tel"> - </span> {{data.end_customer.tel}}</div>
                                    </div>
                                    <!-- Mail -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Adresse email') }}:</div>
                                        <div class="font-bold">{{ data.end_customer.mail || '-' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="data.iad" class="bg-orange-200 rounded mb-2 p-3">
                                <h2 class="text-sm font-bold border-b border-gray-500 pb-2 mb-2"><i class="fas fa-screwdriver-wrench"></i> {{ $tt('Intervention à domicile') }}</h2>
                                <div class="grid grid-cols-4 gap-2">
                                    <input-rule v-model="iadForm.kilometrage_facture" type="number" :label="$tt('Km')" :label-inline="false" class="mb-2" custom="text-xs" />
                                    <div>
                                        <div class="text-xs font-bold">Date</div>
                                        <div class="bg-orange-300 text-xs py-1 mt-2 px-0.5 rounded text-center">{{ iadForm.date_inter | momentDate }}</div>
                                    </div>
                                    <div>
                                        <div class="text-xs font-bold">Arrivée</div>
                                        <div class="bg-orange-300 text-xs py-1 mt-2 px-0.5 rounded text-center">{{ iadForm.heure_debut }}</div>
                                    </div>
                                    <div>
                                        <div class="text-xs font-bold">Départ</div>
                                        <div class="bg-orange-300 text-xs py-1 mt-2 px-0.5 rounded text-center">{{ iadForm.heure_fin }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-orange-200 rounded mb-2 p-3">
                                <button @click="$modal.show('modal-devis-codification')" class="mb-2 bg-orange-600 hover:bg-orange-700 duration-200 text-white rounded px-4 py-3 font-light w-full"><i class="fas fa-pen mr-1"></i> {{ $tt('Saisie des travaux') }}</button>
                                <table class="w-full">
                                    <thead>
                                        <tr>
                                            <th><div class="text-xs text-left font-bold">{{ $tt('Travaux IRIS') }}</div></th>
                                            <th><div class="text-xs text-left font-bold">{{ $tt('DO') }}</div></th>
                                            <th><div class="text-xs text-left font-bold">{{ $tt('MA') }}</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="width: 60%;">
                                                <div class="text-xs bg-orange-100 border-0 p-2 rounded mt-1">
                                                    <span v-if="!data.code_reparation && !data.code_section">-</span>
                                                    {{ codifications[5].find((item) => item.code == data.code_reparation)?.fr }}
                                                    <span v-if="data.code_section">-</span>
                                                    {{ codifications[3].find((item) => item.code == data.code_section)?.fr }}
                                                </div>
                                            </td>
                                            <td style="width: 20%; vertical-align: top"><div class="text-xs bg-orange-100 border-0 p-2 rounded mt-1">0.00 €</div></td>
                                            <td style="width: 20%; vertical-align: top"><div class="text-xs bg-orange-100 border-0 p-2 rounded mt-1">{{ tarifs.details.supp_codes || '0.00' }} €</div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mb-2">
                                <button @click="$modal.show('modal-drive-files')" class="px-4 py-2 text-sm bg-slate-200 hover:bg-slate-300 duration-200 text-xs rounded"><i class="fas fa-file mr-1"></i> {{ $tt('Fichiers') }}</button>
                            </div>

                            <div class="bg-slate-100 rounded mb-2 p-3">
                                <h2 class="text-xs font-bold border-b border-slate-300 text-slate-500 pb-1 mb-2"><i class="fas fa-file mr-1"></i> {{ $tt('Liste des factures') }}</h2>
                                <div class="text-xs font-bold text-slate-400" v-if="data.devis && data.devis.length <= 0">{{ $tt('Aucun devis') }}</div>
                                <div v-for="(devis, devisIndex) in data.devis" :key="'devis-file-' + devisIndex" class="flex justify-between gap-2 items-center mb-1">
                                    <a v-if="devis.devis_sign?.url || devis.devis_url" :href="devis.devis_sign && devis.devis_sign.url ? devis.devis_sign.url : devis.devis_url" target="_blank" class="text-blue-500 hover:underline cursor-pointer text-xs">
                                        <template>{{devis.name}}</template>
                                    </a>
                                    <div class="grow border-b border-slate-300 border-dashed"></div>
                                    <div class="text-xs font-light">
                                        <template v-if="!devis.date_sign">{{ $tt('Envoyé le') }}</template>
                                        <template v-if="devis.date_sign">{{ $tt('Signé le') }}</template>
                                        {{ (devis.date_sign ? devis.date_sign : devis.date_edit) | momentDate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grow">
                            <div class="border shadow-lg shadow-slate-500 p-4 rounded-lg rounded-tl-none">
                                <div class="py-2">
                                    <div class="py-2 flex justify-between items-center">
                                        <div>
                                            <div class="text-xs font-light">{{$tt('Type de garantie')}}</div>
                                            <div class="font-bold flex items-center gap-2">
                                                <div v-if="isGarantie" class="h-[10px] w-[10px] rounded-full bg-green-500"></div>
                                                <div v-if="!isGarantie" class="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                                                <div class="font-bold text-xs">{{ $tt(data.garantie_four?.libelle) }}</div>
                                                <button @click="$modal.show('modal-confirm-change-garantie')" style="font-size: 8px" class="bg-slate-200 hover:bg-slate-300 duration-200 font-light text-slate-600 rounded p-1" v-if="isGarantie"><i class="fas fa-pen"></i></button>
                                            </div>
                                        </div>
                                        <div class="grow flex justify-center">
                                            <span class="font-semibold text-blue-800 text-center rounded text-xl">{{ $tt(data.last_avancement?.libelle_codeano) || '-' }}</span>
                                        </div>
                                        <div class="flex items-center gap-2 w-[375px]">
                                            <a class="px-3 py-2 rounded duration-200 font-bold items-center flex justify-center text-center text-xs" :class="data.notices?.nomenclature ? 'bg-blue-200 hover:bg-blue-300 text-blue-800' : 'bg-slate-200 cursor-not-allowed text-slate-800'" target="_blank" :href="data.notices?.nomenclature">{{ $tt('Nomenclature') }}</a>
                                            <a class="px-3 py-2 rounded duration-200 font-bold items-center flex justify-center text-center text-xs" :class="data.notices?.fiche_tech ? 'bg-blue-200 hover:bg-blue-300 text-blue-800' : 'bg-slate-200 cursor-not-allowed text-slate-800'" target="_blank" :href="data.notices?.fiche_tech">{{ $tt('Fiches Techniques') }}</a>
                                            <a class="px-3 py-2 rounded duration-200 font-bold items-center flex justify-center text-center text-xs" :class="data.notices?.info_tech ? 'bg-blue-200 hover:bg-blue-300 text-blue-800' : 'bg-slate-200 cursor-not-allowed text-slate-800'" target="_blank" :href="data.notices?.info_tech">{{ $tt('Info techniques') }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <table class="mt-2 w-full border bg-white">
                                        <thead class="bg-slate-200">
                                            <tr>
                                                <th class="px-0.5 py-1 text-xs text-left">{{ $tt('Référence') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left">{{ $tt('Désignation') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[105px]">{{ $tt('Issue') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[80px]">{{ $tt('PV Base') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left">{{ $tt('Remise') }} (%)</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[80px]">{{ $tt('PV Net') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[50px]">{{ $tt('Qté') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[80px]">{{ $tt('Total HT') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[120px]">{{ $tt('Facturable') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left">{{ $tt('Coeff') }}</th>
                                                <th class="px-2 py-1 text-xs text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(line, lineIndex) in devisLines" :key="'invoice-line-' + lineIndex">
                                                <td class="px-0.5"><input-rule v-model="line.reference" :disabled="line.edit ? false : true" type="text" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5"><input-rule v-model="line.designation" :disabled="line.edit ? false : true" type="text" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5 w-[105px]"><div class="mt-1 cursor-not-allowed text-xs bg-gray-50 border border-slate-300 p-[6px]">{{ issues[line.int_etat] }}</div></td>
                                                <td class="px-0.5 w-[80px]"><input-rule v-model="line.prix_vente" :disabled="line.edit ? false : true" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5"><input-rule v-model="line.remise" type="select" :options="{values: trancheRemises(line)}" :label-inline="false" custom="rounded-sm text-xs p-1" :disabled="line.facturable != -1" @input="(value) => {updateRemise(line, value)}" /></td>
                                                <td class="px-0.5 w-[80px]"><input-rule :disabled="true" v-model="line.prix_vente_remise" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5 w-[50px]">
                                                    <input-rule v-if="line.int_etat == 8 || line.int_etat == 11" v-model="line.qty" type="select" :label-inline="false" custom="rounded-sm text-xs p-1" @input="(value) => {updateQtt(line, value)}" :options="{values: makeDecrementQtt(line)}" />
                                                    <input-rule v-if="line.int_etat == 3" v-model="line.qty" type="number" :label-inline="false" custom="rounded-sm text-xs p-1" @input="(value) => {updateQtt(line, value)}" :options="{min: 0}" />
                                                    <input-rule v-if="line.int_etat != 8 && line.int_etat != 11 && line.int_etat != 3" v-model="line.qty" :disabled="true" type="number" :label-inline="false" custom="rounded-sm text-xs p-1" />
                                                </td>
                                                <td class="px-0.5 w-[80px]"><input-rule :disabled="true" v-model="line.total_ht" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5 w-[120px]"><input-rule v-model="line.facturable" type="select" :options="{values: facturables, key: 'key', label: 'label'}" :label-inline="false" @input="(value) => {manageFacturableRemise(line); updateFacturable(line, value, lineIndex)}" :custom="bgColorFacturables[line.facturable] + ' ' + 'rounded-sm text-xs p-1 text-slate-900'" /></td>
                                                <td class="px-0.5"><div class="px-4 py-2 rounded" :class="coeffState(line)"></div></td>
                                                <td class="px-2 text-xs text-right">
                                                    <button v-if="!line.edit" @click="selectedLineDelete = line; $modal.show('modal-confirm-delete-line')"><i class="fas fa-trash text-red-500"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="flex gap-2">
                                        <button @click="$modal.show('modal-stock-piece')" class="mt-1 px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded">
                                            <i class="fas fa-plus-circle"></i> {{ $tt('Pièce (Stock / Commande)') }}
                                        </button>
                                        <!-- <button @click="devisLines.push({
                                            reference: '',
                                            designation: '',
                                            int_etat: -1,
                                            prix_vente: '',
                                            remise: 0,
                                            prix_vente_remise: '',
                                            qty: 1,
                                            total_ht: '',
                                            facturable: isGarantie ? '1' : '-1',
                                            edit: true
                                        })" class="mt-1 px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded">
                                            <i class="fas fa-plus-circle"></i> {{$tt('Saisie libre')}}
                                        </button> -->
                                        <button @click="initNewLineForm" class="mt-1 px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded">
                                            <i class="fas fa-plus-circle"></i> {{$tt('Pièce (Saisie libre)')}}
                                        </button>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 my-4">
                                        <div>
                                            <div class="bg-slate-200 rounded mb-2 p-3">
                                                <h2 class="text-sm font-bold border-b border-slate-900 pb-2 mb-2"><i class="fas fa-clock mr-1"></i> {{ $tt('Temps dossier') }}</h2>
                                                <div>
                                                    <div class="text-right flex items-center justify-between gap-2">
                                                        <div class="flex items-center gap-1">
                                                            <select v-model="newTemps" class="text-xs bg-white border-0 p-1 rounded-sm focus:outline-none">
                                                                <option v-for="(temps, tempsIndex) in trancheTemps" :key="'tranche-temps-' + tempsIndex" :value="tempsIndex">{{ temps }}</option>
                                                            </select>
                                                            <button @click="addTemps" class="bg-orange-500 hover:bg-orange-600 duration-200 text-white rounded-sm px-1.5 py-1" style="font-size: 10px;"><i class="fas fa-plus"></i></button>
                                                        </div>
                                                        <div class="flex items-center gap-3">
                                                            <div>
                                                                <div class="font-light text-xs">{{ $tt('Travaillé') }}</div>
                                                                <div class="font-bold text-sm">{{ totalTemps.worked }}</div>
                                                            </div>
                                                            <div>
                                                                <div class="font-light text-xs">{{ $tt('Facturé') }}</div>
                                                                <div v-if="!data.isForfait" class="font-bold text-sm">{{ totalTemps.invoice }}</div>
                                                                <div v-if="data.isForfait" class="font-bold text-sm">{{ $tt('Forfait') }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="border-t mt-2 pt-2 border-slate-400">
                                                        <div class="max-h-[300px] overflow-y-auto">
                                                            <div @click="displayHistoryTemps = !displayHistoryTemps" class="flex items-center justify-between text-xs cursor-pointer">
                                                                <div>{{ $tt('Historique des temps') }}</div>
                                                                <template v-if="!displayHistoryTemps"><i class="fas fa-chevron-right"></i></template>
                                                                <template v-if="displayHistoryTemps"><i class="fas fa-chevron-down"></i></template>
                                                            </div>
                                                            <div class="mt-2" v-if="displayHistoryTemps">
                                                                <div v-for="(tempsList, tempsListIndex) in tempsLists" :key="'temps-' + tempsListIndex" class="mb-2 text-sm flex justify-between items-center">
                                                                    <div class="flex items-center gap-1">
                                                                        <input v-if="!data.isForfait" @input="updateTemps(tempsList)" v-model="tempsList.facturable" type="checkbox" :id="'temps-invoice-' + tempsListIndex" />
                                                                        <div>
                                                                            <div class="flex items-center gap-2">
                                                                                <label :for="'temps-invoice-' + tempsListIndex" class="text-xs">{{ tempsList.technicien.nom }}</label>
                                                                                <div class="text-xs font-light">{{ tempsList.date | momentDateTime }}</div>
                                                                            </div>
                                                                            <div class="text-xs font-light">{{ tempsList.data }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="font-semibold text-xs">{{trancheTemps[tempsList.temps]}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <label class="text-xs font-bold">{{ $tt('Travaux effectués') }}</label>
                                                <div class="text-xs p-2 bg-slate-200 rounded" v-html="data.commentaire_tech || '-'"></div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="border rounded">
                                                <div v-if="tarifs && tarifs.details && !tarifs.details.hasTarif" class="p-2 pb-0">
                                                    <div class="bg-red-200 text-red-800 p-4 rounded">
                                                        <h2 class="font-bold text-sm"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt('Aucun tarif trouvé') }}</h2>
                                                        <p class="text-sm text-xs">{{$tt('Veuillez configurer des tarifs pour cette catégorie de produit, liés à ce client')}}</p>
                                                    </div>
                                                </div>
                                                <div v-if="true">
                                                    <table v-if="tarifs" class="mt-2 w-full text-xs">
                                                        <thead>
                                                            <tr>
                                                                <th class="px-2 py-1"></th>
                                                                <th class="px-2 py-1 text-right">{{ $tt('Marque') }}</th>
                                                                <th class="px-2 py-1 text-right">{{ $tt('D. Ordre') }}</th>
                                                                <th class="px-2 py-1 text-right">{{ $tt('Client final') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('Déplacement') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].deplacement}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].deplacement}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('Port') }}.</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].port}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].port}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('MO') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].mo}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].mo}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('Pièce(s)') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].pieces}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[2].pieces}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].pieces}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('Frais Adm') }}.</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].frais_gestion}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].frais_gestion}} €</td>
                                                            </tr>
                                                            <tr v-if="data.iad">
                                                                <td class="px-2 py-1">{{ $tt('IAD') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].iad}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].iad}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">
                                                                    <div class="flex items-center">
                                                                        <input-rule v-model="data.pret" type="checkbox" :label-inline="false" @input="editPret" />
                                                                        {{ $tt('Prêt') }}
                                                                        <tooltip v-if="tarifs['-1'].pret > 0" :msg="$tt('Montant du prêt') + ': ' + tarifs['-1'].pret + ' €'" class="ml-1" custom="text-slate-800" />
                                                                    </div>
                                                                </td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{data.pret ? tarifs['-1'].pret : '0.00'}} €</td>
                                                            </tr>
                                                            <tr class="font-black border-gray-700">
                                                                <td class="px-2 py-1">{{ $tt('HT') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].total_ht}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[2].total_ht}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].total_ht}} €</td>
                                                            </tr>
                                                            <tr class="font-black border-y-2 border-gray-700 bg-slate-200 text-blue-800">
                                                                <td class="px-2 py-1">{{ $tt('Total TTC') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].total_ttc}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[2].total_ttc}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].total_ttc}} €</td>
                                                            </tr>
                                                            <tr class="font-black border-b-2 border-gray-700">
                                                                <td class="px-2 py-1">{{ $tt('Montant TTC autorisé') }}</td>
                                                                <td class="px-2 py-1 text-right"></td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].montant_min}} €</td>
                                                            </tr>
                                                            <tr v-if="tarifs.details.ecosystem > 0" class="font-black border-b-2 border-green-700 bg-green-200 text-green-800">
                                                                <td class="px-2 py-1">{{$tt('Ecosystème')}}</td>
                                                                <td class="px-2 py-1 text-right"></td>
                                                                <td class="px-2 py-1 text-right"></td>
                                                                <td class="px-2 py-1 text-right">{{ '-' + tarifs.details.ecosystem + ' €' }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div v-if="data.iad" class="px-4 my-4">
                                                        <input-rule type="pcheck" :label="$tt('Nécessite un nouveau passage chez le client')" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between border-t-2 pt-3 mt-4">
                                        <div class="flex items-center gap-2">
                                            <button @click="emailComplement = data.end_customer.mail; $modal.show('modal-demande-complement-msg')" class="px-4 py-2 duration-200 rounded text-xs bg-slate-200 hover:bg-slate-300 duration-200 text-slate-500">
                                                <i class="fas fa-comment mr-1"></i> {{ $tt('Complément panne') }}
                                            </button>
                                            <button @click="$modal.show('modal-confirm-chauffe')" class="px-4 py-2 duration-200 rounded text-xs bg-slate-200 hover:bg-slate-300 duration-200 text-slate-500">
                                                <i class="fas fa-comment mr-1"></i> {{ $tt('Appareil en chauffe') }}
                                            </button>
                                        </div>
                                        <div>
                                            <div v-if="loadingMakeInvoice" class="p-2 flex items-center justify-end">
                                                <spinner :size="10" />
                                            </div>
                                            <template v-if="!loadingMakeInvoice">
                                                <template v-if="!isGarantie && tarifs && tarifs.details && tarifs.details.hasTarif">
                                                    <button v-if="canEditDevis" :disabled="loadingMakeInvoice" @click="makeInvoice('devis')" class="px-4 py-2 duration-200 rounded text-sm mr-2 bg-green-500 hover:bg-green-600 text-white">
                                                        <template v-if="!loadingMakeInvoice">
                                                            <i v-if="canEditDevis" class="fas fa-check mr-1"></i>
                                                            <i v-if="!canEditDevis" class="fas fa-ban mr-1"></i>
                                                            {{ $tt("Générer le devis") }}
                                                        </template>
                                                    </button>
                                                    <button v-if="!canEditDevis && !piecesEnCommande" @click="makeInvoice('facture')" class="px-4 py-2 duration-200 rounded text-sm mr-2 bg-green-500 hover:bg-green-600 text-white">
                                                        <template v-if="!loadingMakeInvoice">
                                                            <i class="fas fa-check-circle mr-1"></i>
                                                            {{ $tt("Terminer la réparation") }}
                                                        </template>
                                                    </button>
                                                </template>
                                                <template v-if="isGarantie">
                                                    <button @click="endDossier" class="px-4 py-2 duration-200 rounded text-sm mr-2 bg-green-500 hover:bg-green-600 text-white">
                                                        <spinner v-if="loadingEndDossier" :size="10" />
                                                        <template v-if="!loadingEndDossier">
                                                            <i class="fas fa-check mr-1"></i>
                                                            {{ $tt("Intervention terminée") }}
                                                        </template>
                                                    </button>
                                                </template>
                                                <button :disabled="loadingSave" @click="saveAndClose" class="px-4 py-2 duration-200 rounded text-sm bg-green-200 hover:bg-green-300 duration-200">
                                                    <spinner v-if="loadingSave" :size="10" />
                                                    <template v-if="!loadingSave">
                                                        <i class="fas fa-check mr-1"></i> {{ $tt('Enregistrer et fermer') }}
                                                    </template>
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-devis-codification" width="80%" height="auto" :scrollable="true">
            <div>
                <div class="flex items-center justify-between bg-orange-100 px-4 py-2">
                    <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Saisie des travaux') }}</div>
                </div>
            </div>
            <div v-if="data" class="p-4 min-h-[600px]">
                <h2 class="font-bold">{{ $tt('Codification des travaux effectués') }}</h2>
                <div class="grid grid-cols-3 gap-4 bg-slate-100 rounded p-4 mt-4">
                    <div>
                        <input-rule @input="updateCode('code_defaut')" v-model="data.code_defaut" type="select" :options="{values: codifications[4], label: 'fr', key: 'code'}" :label="$tt('Code défaut')" :label-inline="false" />
                    </div>
                    <div>
                        <input-rule @input="updateCode('code_condition')" v-model="data.code_condition" type="select" :options="{values: codifications[1], label: 'fr', key: 'code'}" :label="$tt('Condition')" :label-inline="false" />
                    </div>
                    <div>
                        <input-rule @input="updateCode('code_symptome')" v-model="data.code_symptome" type="select" :options="{values: codifications[2], label: 'fr', key: 'code'}" :label="$tt('Code panne')" :label-inline="false" />
                    </div>
                    <div>
                        <input-rule @input="updateCode('code_reparation'); getMessages()" v-model="data.code_reparation" type="select" :options="{values: codifications[5], label: 'fr', key: 'code'}" :label="$tt('Solution')" :label-inline="false" />
                    </div>
                    <div>
                        <input-rule @input="updateCode('code_section')" v-model="data.code_section" type="select" :options="{values: codifications[3], label: 'fr', key: 'code'}" :label="$tt('Secteur')" :label-inline="false" />
                    </div>
                    <div>
                        <div class="grid grid-cols-3 gap-2">
                            <input-rule v-model="data.rep_pla" type="text" :label="$tt('Platine')" @input="updateCode('rep_pla')" :label-inline="false" />
                            <input-rule v-model="data.rep_el" type="text" :label="$tt('Elément')" @input="updateCode('rep_el')" :label-inline="false" />
                            <input-rule v-model="data.rep_firm" type="text" :label="$tt('Firmware')" @input="updateCode('rep_firm')" :label-inline="false" />
                        </div>
                    </div>
                </div>
                <div class="rounded p-4 mt-8">
                    <h2 class="font-bold">{{ $tt('Détail des travaux effectués') }}</h2>
                    <div class="mt-4">
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <div class="mb-1 -mt-1">
                                    <label class="font-bold text-xs">{{ $tt('Messages enregistrés') }}</label>
                                </div>
                                <div class="p-4" v-if="loadingGetMessages">
                                    <spinner :size="10" />
                                </div>
                                <div class="bg-slate-100 rounded p-2 text-xs font-light text-slate-800" v-if="!loadingGetMessages && defaultMessages && defaultMessages.length <= 0">
                                    {{ $tt('Aucun message enregistré') }}
                                </div>
                                <div @click="selectDefaultMessage(msg)" class="border mb-2 rounded hover:bg-slate-100 duration-200 cursor-pointer p-2 flex items-center gap-2" v-for="(msg, msgIndex) in defaultMessages" :key="'default-msg-' + msgIndex">
                                    <div class="w-[90%] text-sm" v-html="msg.titre"></div>
                                    <div class="w-[10%] flex items-center justify-end gap-4">
                                        <button @click.stop="msg.confirmDelete = !msg.confirmDelete" class="text-right text-sm text-red-500"><i class="fas fa-times"></i></button>
                                        <button v-if="msg.confirmDelete" @click.stop="deleteDefaultMessage(msg)" style="font-size: 10px" class="text-right bg-red-500 text-white p-1 px-2 rounded"><i class="fas fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="flex items-center gap-2 justify-between mb-1">
                                    <label class="text-xs font-bold">{{ $tt('Détails des travaux') }}</label>
                                </div>
                                <input-rule @input="updateTravaux" v-model="data.commentaire_tech" type="richtext" :options="{rows: 3}" :label-inline="false" custom="rounded-sm text-xs" />
                                <div v-if="data.commentaire_tech && data.code_reparation && (defaultMessages && !defaultMessages.find((msg) => msg.message == data.commentaire_tech))" class="text-right">
                                    <button :disabled="loadingDefaultNewMessage" @click="$modal.show('modal-save-default-message')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded mt-1 text-xs font-light">
                                        <spinner v-if="loadingDefaultNewMessage" :size="10" />
                                        <template v-if="!loadingDefaultNewMessage">
                                            {{$tt('Ajouter aux messages enregistrés')}} <i class="fas fa-save ml-0.5"></i>
                                        </template>
                                    </button>
                                </div>
                                <input-rule v-if="data.iad" type="pcheck" :label="$tt('Fact. Report')" :label-inline="true" class="my-3" custom="text-xs"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-4">
                    <button @click="$modal.hide('modal-devis-codification')" class="px-4 py-2 rounded bg-orange-100 hover:bg-orange-200 duration-200 text-sm"><i class="fas fa-times mr-1"></i> {{ $tt('Fermer la fenêtre') }}</button>
                </div>
            </div>
        </modal>

        <modal name="modal-denonce-garantie" width="50%" height="auto" :scrollable="true" :click-to-close="false">
            <div>
                <div class="flex items-center justify-between bg-slate-100 px-4 py-1">
                    <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Dénoncer la garantie') }}</div>
                    <button @click="$modal.hide('modal-denonce-garantie')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
            </div>
            <div class="p-4">
                <input-rule type="richtext" />
                <div class="text-center mt-4">
                    <button class="px-4 py-2 rounded bg-green-500 hover:bg-green-600 duration-200 text-white text-sm"><i class="fas fa-check mr-1"></i> {{ $tt('Confirmer') }}</button>
                </div>
            </div>
        </modal>

        <!-- Modal confirm delete -->
        <modal name="modal-confirm-delete-line" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLineDelete">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Confirmer la suppression')}}
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-line')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div>{{ $tt('Souhaitez-vous supprimer cette ligne') }} ?</div>
                    <div class="bg-slate-100 p-4 rounded mt-4 text-sm">
                        <div>{{$tt('Référence')}}: <b>{{selectedLineDelete.reference}}</b></div>
                        <div>{{$tt('Désignation')}}: <b>{{selectedLineDelete.designation}}</b></div>
                        <!-- <div class="border-b pb-1 mb-1">{{$tt('Entite')}}: <b>{{ selectedLine.entite_key }}</b></div>
                        <div class="border-b pb-1 mb-1">{{$tt('Catégorie')}}: <b>{{ selectedLine.categorie_key }}</b></div>
                        <div>{{$tt('Zone')}}: <b>{{ selectedLine.zone_intervention_key }}</b></div> -->
                    </div>
                    <div class="flex gap-2 items-center mt-4">
                        <button @click="$modal.hide('modal-confirm-delete-line')" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200">Annuler <i class="fas fa-times ml-1"></i></button>
                        <button :disabled="loadingDelete" @click="deleteLine" class="px-4 py-2 rounded text-sm bg-red-500 hover:bg-red-600 duration-200 text-white">
                            <spinner :size="10" v-if="loadingDelete" />
                            <template v-if="!loadingDelete">
                                {{$tt('Supprimer')}} <i class="fas fa-trash ml-1"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-end-dossier" height="auto" width="80%" :scrollable="true">
            <div class="p-5">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt(canEditDevis ? 'Devis' : 'Facture')}}
                    </h2>
                    <button @click="$modal.hide('modal-end-dossier')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="bg-slate-100 rounded p-4 mb-4">
                        <div>
                            <input-rule v-model="emailEnvoieClient" type="text" :label="$tt('Adresse email du client')" :label-inline="false" id="inp-email-envoie-facture" />
                        </div>
                        <div class="mt-2 flex items-center gap-2">
                            <button @click="sendFacture(canEditDevis ? 'devis' : 'facture')" class="px-4 py-2 duration-200 rounded text-xs mr-2 bg-slate-500 hover:bg-slate-600 text-white">
                                <spinner v-if="loadingSendFactureEmail" :size="10" />
                                <template v-if="!loadingSendFactureEmail">
                                    <i class="fas fa-paper-plane mr-1"></i>
                                    {{ $tt("Envoyer " + (canEditDevis ? 'le devis' : 'la facture') + " au client") }}
                                </template>
                            </button>
                        </div>
                    </div>
                    <iframe class="w-full h-[800px]" :src="generatedDevisUrl" frameborder="0"></iframe>
                </div>
            </div>
        </modal>

        <!-- Modal confirm delete -->
        <!-- <modal name="modal-default-messages" height="auto" width="60%" :scrollable="true">
            <div class="p-5">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Liste des travaux')}}
                    </h2>
                    <button @click="$modal.hide('modal-default-messages')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="grid grid-cols-2 gap-10">
                        <div>
                            <input-rule v-model="defaultNewMessageInp" type="richtext" :options="{rows: 3}" :label="$tt('Enregistrer un nouveau message')" :label-inline="false" custom="rounded-sm text-xs" />
                            <button :disabled="loadingDefaultNewMessage" @click="addNewDefaultMessage" class="px-4 py-2 text-xs bg-green-500 hover:bg-green-600 duration-200 text-white rounded mt-2">
                                <spinner :size="10" v-if="loadingDefaultNewMessage" />
                                <template v-if="!loadingDefaultNewMessage">
                                    <i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter') }}
                                </template>
                            </button>
                        </div>
                        <div>
                            <label class="font-bold text-xs">{{ $tt('Messages enregistrés') }}</label>
                            <div @click="selectDefaultMessage(msg)" class="border mb-2 rounded hover:bg-green-100 duration-200 cursor-pointer p-2 flex items-center gap-2" v-for="(msg, msgIndex) in defaultMessages" :key="'default-msg-' + msgIndex">
                                <div class="w-[90%] text-sm" v-html="msg.message"></div>
                                <button @click.stop="deleteDefaultMessage(msg)" class="w-[10%] text-right text-sm text-red-500"><i class="fas fa-trash"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal> -->

        <modal name="modal-confirm-change-garantie" height="auto" :scrollable="true">
            <div class="p-5">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Changement de garantie')}}
                    </h2>
                    <button @click="$modal.hide('modal-confirm-change-garantie')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div v-if="data">
                    <div class="flex items-center gap-2 font-bold bg-orange-200 rounded-t p-4 text-orange-800">
                        <i class="fas fa-exclamation-triangle mr-1 text-xl"></i>
                        <div>
                            <div>{{ $tt('Confirmez-vous le changement de garantie') }} ?</div>
                            <div class="text-sm font-semibold text-orange-800">Ce changement sera irréversible</div>
                        </div>
                    </div>
                    <div class="flex items-center gap-3 text-sm border-2 border-t-0 rounded-b border-orange-200 p-4">
                        <div class="font-bold flex items-center gap-2">
                            <div class="h-[10px] w-[10px] rounded-full bg-green-500"></div>
                            {{ $tt(data.garantie_four?.libelle) }}
                        </div>
                        <div><i class="fas fa-arrow-right"></i></div>
                        <div class="font-bold flex items-center gap-2 text-red-500">
                            <div class="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                            {{ $tt('Hors garantie') }}
                        </div>
                    </div>
                    <div v-if="!data.end_customer || data.end_customer.id == 0" class="mt-4 border-2 rounded bg-slate-50">
                        <span class="bg-slate-200 rounded-br text-xs px-3 py-2"><i class="fas fa-user mr-0.5"></i> {{ $tt('Fiche client') }}</span>
                        <div class="p-4">
                            <input-rule v-model="formGarantieClient.nom" class="mb-2" type="text" :label-inline="false" :label="$tt('Nom')" id="inp-change-nom" custom="bg-white" />
                            <input-rule v-model="formGarantieClient.email" class="mb-2" type="text" :label-inline="false" :label="$tt('Adresse email')" id="inp-change-email" custom="bg-white" />
                            <input-rule v-model="formGarantieClient.tel" class="mb-2" type="tel" :label-inline="false" :label="$tt('Téléphone')" id="inp-change-tel" />
                            <div class="grid grid-cols-2 gap-4">
                                <input-rule v-model="formGarantieClient.cp" class="mb-2" type="text" :label-inline="false" :label="$tt('Code postal')" id="inp-change-cp" custom="bg-white" />
                                <input-rule v-model="formGarantieClient.ville" class="mb-2" type="text" :label-inline="false" :label="$tt('Ville')" id="inp-change-ville" custom="bg-white" />
                            </div>
                            <div class="bg-slate-200 p-4 rounded mt-2 text-sm font-bold">
                                <div class="flex items-center">
                                    <input-rule v-model="formGarantieClient.confirm_optin" type="checkbox" :label-inline="false" />
                                    <div @click="formGarantieClient.confirm_optin = !formGarantieClient.confirm_optin" for="inp-change-confirm_optin" class="font-bold cursor-pointer">{{$tt('Le client consent à transmettre ses coordonnées')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button :disabled="loadingRemoveGarantie" @click="removeGarantie" class="px-4 py-2 bg-orange-200 hover:bg-orange-300 duration-200 text-orange-800 rounded mt-4 text-sm font-bold">
                        <spinner v-if="loadingRemoveGarantie" :size="10" />
                        <template v-if="!loadingRemoveGarantie">
                            <i class="fas fa-retweet mr-1"></i> {{ $tt('Changer la garantie') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-add-devis-line" height="auto" :scrollable="true">
            <div class="p-5">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Saisie libre')}}
                    </h2>
                    <button @click="$modal.hide('modal-add-devis-line')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="grid grid-cols-2 gap-2">
                        <input-rule :label="$tt('Référence')" v-model="newLine.reference" type="text" :label-inline="false" custom="rounded-sm text-xs p-1" />
                        <input-rule :label="$tt('Désignation')" v-model="newLine.designation" type="text" :label-inline="false" custom="rounded-sm text-xs p-1" />
                    </div>
                    <div class="grid grid-cols-6 gap-2 mt-4 border-t pt-2 mt-2">
                        <input-rule :label="$tt('Prix HA') + ' (HT)'" v-model="newLine.prix_ha_net" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" @input="getCoeff(newLine)" />
                        <input-rule :label="$tt('PV Base') + ' (HT)'" v-model="newLine.prix_vente" :disabled="true" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" />
                        <input-rule :label="$tt('Remise')" v-model="newLine.remise" type="select" :options="{values: trancheRemises(newLine)}" :label-inline="false" custom="rounded-sm text-xs p-1" :disabled="newLine.facturable != -1" @input="(value) => {updateRemise(newLine, value); calculEditAmount()}" id="inp-remise-saisie-libre" />
                        <input-rule :label="$tt('PV Net') + ' (HT)'" :disabled="true" v-model="newLine.prix_vente_remise" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" />
                        <input-rule :label="$tt('Qté')" v-model="newLine.qty" type="number" :label-inline="false" custom="rounded-sm text-xs p-1" @input="(value) => {updateQtt(newLine, value); calculEditAmount()}" :options="{min: 1}" />
                        <!-- <input-rule :label="$tt('Total HT')" :disabled="true" v-model="newLine.total_ht" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" /> -->
                        <div class="mr-1">
                            <div class="text-xs font-bold">{{ $tt('Coeff') }}</div>
                            <div class="mt-1 rounded h-[30px]" :class="coeffState(newLine)"></div>
                        </div>
                        <!-- <input-rule :label="$tt('Facturable')" v-model="newLine.facturable" type="select" :options="{values: facturables, key: 'key', label: 'label'}" :label-inline="false" @input="calculEditAmount(); manageFacturableRemise(newLine)" :custom="bgColorFacturables[newLine.facturable] + ' ' + 'rounded-sm text-xs p-1'" /> -->
                    </div>
                    <div class="flex items-center justify-end gap-2 mt-4 pt-2 border-t">
                        <button class="bg-slate-200 hover:bg-slate-300 duration-200 rounded px-4 py-2 text-sm" @click="newLine = {}; $modal.hide('modal-add-devis-line')"><i class="fas fa-times mr-1"></i> {{$tt('Annuler')}}</button>
                        <button :disabled="loadingAddSaisieLibre" class="bg-green-500 hover:bg-green-600 duration-200 rounded text-white text-sm px-4 py-2" @click="saveEditPiece">
                            <spinner v-if="loadingAddSaisieLibre" :size="10" />
                            <template v-if="!loadingAddSaisieLibre">
                                <i class="fas fa-plus mr-1"></i> {{$tt('Ajouter')}}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-save-default-message" height="auto" :scrollable="true">
            <div class="p-5" v-if="data">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Enregistrer le message')}}
                    </h2>
                    <button @click="$modal.hide('modal-save-default-message')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="titleNewDefaultMessage" type="text" :label="$tt('Titre du message')" :label-inline="false" id="inp-title-default-message" />
                    <div class="bg-slate-100 p-4 rounded text-xs mt-4">
                        <div v-html="data.commentaire_tech"></div>
                    </div>
                    <button :disabled="loadingDefaultNewMessage" class="mt-4 px-4 py-2 text-xs rounded bg-green-600 hover:bg-green-700 duration-200 text-white" @click="addNewDefaultMessage">
                        <spinner v-if="loadingDefaultNewMessage" :size="10" />
                        <template v-if="!loadingDefaultNewMessage">
                            {{ $tt('Enregistrer') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-demande-complement-msg" height="auto" width="50%" :scrollable="true">
            <div class="p-5" v-if="data">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Demande complément de panne')}}
                    </h2>
                    <button @click="$modal.hide('modal-demande-complement-msg')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="flex flex-items-center gap-4">
                        <div>
                            <div class="bg-blue-200 w-[300px] p-4 rounded mb-2">
                                <h2 class="text-xs font-bold border-b border-gray-500 pb-2 mb-2"><i class="fas fa-user mr-1"></i> {{ data.end_customer.nom + ' ' + data.end_customer.prenom }}</h2>
                                <div class="grid grid-cols-1 gap-2">
                                    <!-- Tel -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Téléphone') }}:</div>
                                        <div class="font-bold">{{ data.end_customer.gsm || '-' }} <span v-if="data.end_customer.gsm && data.end_customer.tel"> - </span> {{data.end_customer.tel}}</div>
                                    </div>
                                    <!-- Mail -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Adresse email') }}:</div>
                                        <div class="font-bold">{{ data.end_customer.mail || '-' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grow">
                            <div class="border p-3 rounded text-sm mb-1" v-for="(question, questionIndex) in data.complements" :key="'question-complement-' + questionIndex">
                                <div class="font-bold" v-html="question.question"></div>
                                <div class="flex items-center gap-1.5 font-light mt-0.5">
                                    <i class="fa-solid fa-comment"></i> <div v-html="question.reponse"></div>
                                </div>
                                <div class="border-t mt-2 pt-2" v-if="question.reponse_files && JSON.parse(question.reponse_files).length > 0">
                                    <div v-for="(file, fileIndex) in JSON.parse(question.reponse_files)" :key="'file-comp-' + fileIndex" class="flex items-center gap-2 mt-1">
                                        <a target="_blank" :href="file" class="text-blue-500 text-sm hover:underline"><i class="fas fa-link mr-1"></i> {{$tt('Fichier') + ' #' + (fileIndex + 1) }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="border p-3 rounded text-sm">
                                <input-rule class="mb-2" v-if="!data.end_customer.mail" v-model="emailComplement" :value="data.end_customer.mail" :disabled="data.end_customer.mail ? true : false" type="text" :label="$tt('Destinataire')" :label-inline="false" :tooltip="$tt('Adresse email du client')" id="inp-email-complement" />
                                <input-rule v-model="msgComplement" type="richtext" :label="$tt('Question à demander au client')" :label-inline="false" class="mb-2" id="inp-msg-complement" />
                                <input-rule type="file" @input="(data) => { if (data) {filesComplement.push(data.url)}}" :label="$tt('Joindre un fichier')" :label-inline="false" class="mb-2" :options="{preview: false}" />
                                <div v-for="(file, fileIndex) in filesComplement" :key="'file-comp-' + fileIndex" class="flex items-center gap-2">
                                    <button @click="filesComplement.splice(fileIndex, 1)"><i class="fas fa-times text-red-500"></i></button> <a target="_blank" :href="file" class="text-blue-500 hover:underline">{{$tt('Fichier') + ' #' + (fileIndex + 1) }}</a>
                                </div>
                                <button :disabled="loadingSendComplement" @click="sendComplementMsg" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-xs text-white mt-2">
                                    <spinner v-if="loadingSendComplement" :size="10" />
                                    <template v-if="!loadingSendComplement">
                                        <i class="fas fa-check mr-1"></i> {{ $tt('Envoyer la demande') }}
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-chauffe" height="auto" :scrollable="true">
            <div class="p-5" v-if="data">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Confirmer la mise en chauffe')}}
                    </h2>
                    <button @click="$modal.hide('modal-confirm-chauffe')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div class="flex items-center gap-2">
                    <button @click="$modal.hide('modal-confirm-chauffe')" class="px-4 py-2 rounded text-sm font-bold bg-slate-200 hover:bg-slate-300 duration-200">{{ $tt('Annuler') }}</button>
                    <button :disabled="loadingChauffe" @click="sendChauffe" class="px-4 py-2 rounded text-sm font-bold bg-green-200 hover:bg-green-300 duration-200">
                        <spinner v-if="loadingChauffe" :size="10" />
                        <template v-if="!loadingChauffe">
                            {{ $tt('Confirmer') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-drive-files" height="auto" :scrollable="true" width="50%">
            <div class="p-5">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Fichiers')}}
                    </h2>
                    <button @click="$modal.hide('modal-drive-files')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div v-if="selectedDrive" class="bg-gray-900 my-4 h-[500px] flex items-center rounded relative">
                        <a target="_blank" :href="selectedDrive.url" class="right-2 bottom-2 absolute">
                            <i class="fa-solid fa-expand text-white text-2xl"></i>
                        </a>
                        <template v-if="['jpg', 'jpeg', 'png', 'gif'].includes(selectedDrive.url.split('.')[selectedDrive.url.split('.').length - 1])">
                            <img class="mx-auto max-h-[500px]" :src="selectedDrive.url">
                        </template>
                        <template v-else-if="['pdf'].includes(selectedDrive.url.split('.')[selectedDrive.url.split('.').length - 1])">
                            <iframe :src="selectedDrive.url" frameborder="0" class="h-[500px] w-full"></iframe>
                        </template>
                        <template v-else>
                            <a target="_blank" :href="selectedDrive.url" class="right-2 bottom-2 absolute">
                                {{ $tt('Ouvrir le fichier') }}
                            </a>
                        </template>
                    </div>
                    <div>
                        <input id="fileUpload" type="file" hidden @change="uploadPhoto">
                        <button class="text-xs rounded bg-slate-200 hover:bg-slate-300 duration-200 justify-center items-center flex cursor-pointer px-4 py-2" @click="openInpPhoto()">
                            <i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter un fichier') }}
                        </button>
                    </div>
                    <div v-if="data" class="grid grid-cols-6 gap-2 mt-2 max-h-[500px] overflow-y-auto">
                        <div class="relative rounded" v-for="(img, imgIndex) in data.app_imgs" :key="'gallery-img-' + imgIndex" :class="selectedDrive && img.url == selectedDrive.url ? 'border-2 border-slate-600' : 'border-2 border-slate-100'">
                            <!-- <a :hre="img.url" target="_blank"> -->
                                <template v-if="['jpg', 'jpeg', 'png', 'gif'].includes(img.url.split('.')[img.url.split('.').length - 1])">
                                    <div @click="selectedDrive = (selectedDrive && selectedDrive.url == img.url ? null : img)" class="w-full h-[60px] rounded hover:opacity-70 duration-200" :style="'background-image: url(' + img.url + ')'" style="background-size: cover; background-position: center center;"></div>
                                </template>
                                <template v-else-if="['pdf'].includes(img.url.split('.')[img.url.split('.').length - 1])">
                                    <div @click="selectedDrive = (selectedDrive && selectedDrive.url == img.url ? null : img)" class="w-full h-[60px] rounded hover:opacity-70 duration-200 bg-slate-50">
                                        <i class="far fa-file-pdf top-[20%] left-[40%] absolute text-2xl text-red-500"></i>
                                    </div>
                                </template>
                                <template v-else>
                                    <div @click="selectedDrive = (selectedDrive && selectedDrive.url == img.url ? null : img)" class="w-full h-[60px] rounded hover:opacity-70 duration-200 bg-slate-50">
                                        <i class="far fa-file top-[20%] left-[40%] absolute text-2xl text-red-500"></i>
                                    </div>
                                </template>
                            <!-- </a> -->
                            <button @click="deleteImg(img)" class="absolute bottom-0 right-0 text-white bg-red-400 hover:bg-red-600 p-1 px-2 rounded-br rounded-tl" style="font-size: 8px">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal-stock-piece
        v-if="idDossier && data"
        :id-dossier="idDossier"
        @added="getDevisLines(); getTarifAmount()"
        :force-fournisseur="isGarantie && data.grossiste.cmd_oblig_sg ? data.grossiste.id : null"
        />
    </div>
</template>

<script>
import moment from 'moment'
import apiUploadService from '@/services/apiUpload.service'
import ModalStockPiece from './ModalStockPiece.vue'

export default {
    components: {
        ModalStockPiece,
    },
    props: {
        idDossier: {
            required: true
        },
    },
    watch: {
        idDossier: {
            deep: true,
            handler() {
                this.init()
            }
        },
        iadForm: {
            deep: true,
            handler() {
                this.updateIadForm()
            }
        },
    },
    data() {
        return {
            data: null,

            newTemps: 900,
            tempsLists: [],
            displayHistoryTemps: false,

            iadForm: {},
            iadTimer: null,

            codifications: [],
            timerSendCodification: null,

            issues: {},

            timerTravaux: null,

            tarifs: null,

            devisLines: [],
            devisLinesDefault:[],
            timerEditQtt: null,
            selectedLineDelete: null,
            loadingDelete: false,
            coeffStaMin: 0,
            
            newLine: {},
            timerGetCoeff: null,
            coeffNewLine: 1,
            loadingAddSaisieLibre: false,

            emailComplement: null,
            msgComplement: null,
            filesComplement: [],
            loadingSendComplement: false,

            selectedDrive: null,

            loadingGetData: false,

            loadingMakeInvoice: false,
            loadingEndDossier: false,
            emailEnvoieClient: null,
            generatedDevisUrl: null,
            loadingSendFactureEmail: false,

            defaultMessages: [],
            defaultNewMessageInp: null,
            loadingDefaultNewMessage: false,
            loadingGetMessages: false,
            titleNewDefaultMessage: null,

            loadingChauffe: false,

            loadingAddPhoto: false,

            loadingRemoveGarantie: false,
            formGarantieClient: {},

            loadingSave: false,
        }
    },
    computed: {
        trancheTemps() {
            return {
                900: "00:15", 1800: "00:30", 2700: "00:45",
                3600: "01:00", 4500: "01:15", 5400: "01:30", 6300: "01:45",
                7200: "02:00", 8100: "02:15", 9000: "02:30", 9900: "02:45",
                10800: "03:00", 11700: "03:15", 12600: "03:30", 13500: "03:45",
                14400: "04:00", 15300: "04:15", 16200: "04:30", 17100: "04:45",
                18000: "05:00", 18900: "05:15", 19800: "05:30", 20700: "05:45",
                21600: "06:00", 22500: "06:15", 23400: "06:30", 24300: "06:45",
                25200: "07:00", 26100: "07:15", 27000: "07:30", 27900: "07:45",
                28800: "08:00", 29700: "08:15", 30600: "08:30", 31500: "08:45",
                32400: "09:00", 33300: "09:15", 34200: "09:30", 35100: "09:45",
                36000: "10:00", 36900: "10:15", 37800: "10:30", 38700: "10:45",
                39600: "11:00", 40500: "11:15", 41400: "11:30", 42300: "11:45",
                43200: "12:00", 44100: "12:15", 45000: "12:30", 45900: "12:45",
                46800: "13:00", 47700: "13:15", 48600: "13:30", 49500: "13:45",
                50400: "14:00", 51300: "14:15", 52200: "14:30", 53100: "14:45",
                54000: "15:00", 54900: "15:15", 55800: "15:30", 56700: "15:45",
                57600: "16:00", 58500: "16:15", 59400: "16:30", 60300: "16:45",
                61200: "17:00", 62100: "17:15", 63000: "17:30", 63900: "17:45",
                64800: "18:00", 65700: "18:15", 66600: "18:30", 67500: "18:45",
                68400: "19:00", 69300: "19:15", 70200: "19:30", 71100: "19:45",
                72000: "20:00", 72900: "20:15", 73800: "20:30", 74700: "20:45",
                75600: "21:00", 76500: "21:15", 77400: "21:30", 78300: "21:45",
                79200: "22:00", 80100: "22:15", 81000: "22:30", 81900: "22:45",
                82800: "23:00", 83700: "23:15", 84600: "23:30", 85500: "23:45"
            }
        },

        totalTemps() {
            let tempsWorked = this.tempsLists.map((item) => item.temps).reduce((partialSum, a) => partialSum + a, 0)
            let tempsInvoice = this.tempsLists.filter((item) => item.facturable == 1).map((item) => item.temps).reduce((partialSum, a) => partialSum + a, 0)
            return {
                worked: moment.utc(tempsWorked * 1000).format('HH:mm').replace(':', 'h'),
                invoice: moment.utc(tempsInvoice * 1000).format('HH:mm').replace(':', 'h')
            }
        },

        isGarantie() {
            return this.data && !(!this.data.garantie_four.garantie || this.data.garantie_four.garantie == 'hors_garantie')
        },

        // issues() {
        //     return [
        //         {'label': 'Stock - Neuf', key: '8'},
        //         {'label': 'Stock - Occas.', key: '11'},
        //         {'label': 'A commander', key: '3'},
        //         {'label': 'Hors stock', key: '-1'},
        //         {'label': 'Cmd annulée', key: '15'},
        //         {'label': 'Pièce reçue', key: '17'}
        //         {'label': 'En commande', key: '18'}
        //     ]
        // },

        facturables() {
            return [
                {key: -1, label: 'Client final'},
                {key: 1, label: 'Marque'},
                {key: 2, label: "Donneur d'ordre"},
                {key: 4, label: 'Interne'},
            ]
        },

        bgColorFacturables() {
            return {
                '-1': 'bg-lime-200',
                1: 'bg-yellow-200',
                2: 'bg-purple-200',
                4: 'bg-red-200',
            }
        },

        canEditDevis() {
            if (this.tarifs && this.tarifs.details.hasTarif) {
                return parseFloat(this.tarifs['-1'].total_ttc) > parseFloat(this.tarifs['-1'].montant_min)
            }
            return false
        },

        piecesEnCommande() {
            let commandes = this.devisLines.filter((item) => item.int_etat == 3 || item.int_etat == 18);
            
            return commandes.length > 0 ? true : false 
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },

        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async init() {
            this.loadingGetData = true
            await this.getStatus()
            await this.getDossier()
            await this.getCodifications()
            await this.getDevisLines()
            this.loadingGetData = false
            await this.getTarifAmount()
        },

        async getStatus() {
            let response = await this.$request.post('technicien.get-pieces-statut')
            this.issues = response.data.posts.post
        },

        async getDossier() {
            let response = await this.$request.post('technicien.get-dossier-devis', {
                id_dossier: this.idDossier
            })
            this.data = response.data.posts.post
            
            this.tempsLists = this.data.temps_reparations
            if (this.data.last_intervention) {
                this.iadForm = {
                    id: this.data.last_intervention.id,
                    kilometrage_facture: this.data.last_intervention.kilometrage_facture,
                    date_inter: this.data.last_intervention.date_inter,
                    heure_debut: this.data.last_intervention.heure_debut,
                    heure_fin: this.data.last_intervention.heure_fin
                }
            }
        },

        async getTemps() {
            let response = await this.$request.post('technicien.temps.list-dossier', {
                id_dossier: this.data.id_dossiersav
            })
            this.tempsLists = response.data.posts.post
        },

        async addTemps() {
            let response = await this.$request.post('technicien.temps.add', {
                temps: this.newTemps,
                id_dossier: this.data.id_dossiersav
            })
            if (response.data.posts.post?.id) {
                this.getTemps()
                this.newTemps = 900
                this.$toast.success(this.$tt('Temps ajouté'))
                this.getTarifAmount()
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async updateTemps(temps) {
            await this.$request.post('technicien.temps.edit', {
                line_id: temps.id,
                facturable: temps.facturable ? 0 : 1
            })
            this.getTarifAmount()
        },

        async updateIadForm() {
            clearTimeout(this.iadTimer)
            this.iadTimer = setTimeout(async () => {
                let response = await this.$request.post('technicien.iad.edit', {
                    intervention_id: this.iadForm.id,
                    kilometrage_facture: this.iadForm.kilometrage_facture
                })
                if (response.data.posts.post != 1) {
                    this.$toast.error(this.$tt('Une erreur est survenue'))
                }
            }, 600)
        },

        async getCodifications() {
            let response = await this.$request.post('technicien.codification.get', {
                id_dossier: this.idDossier
            })
            this.codifications = response.data.posts.post
            this.getMessages()
        },

        async updateCode(keyname) {
            clearTimeout(this.timerSendCodification)
            this.timerSendCodification = setTimeout(async () => {
                await this.$request.post('technicien.codification.update', {
                    id_dossier: this.idDossier,
                    type_code: keyname,
                    code: this.data[keyname] ? this.data[keyname] : ''
                })
                this.getTarifAmount()
            }, 500)
        },

        async updateTravaux() {
            clearTimeout(this.timerTravaux)
            this.timerTravaux = setTimeout(async () => {
                await this.$request.post('technicien.travaux.edit', {
                    id_dossier: this.idDossier,
                    commentaire_tech: this.data.commentaire_tech ? this.data.commentaire_tech : ''
                })
            }, 800)
        },

        async getTarifAmount() {
            let response = await this.$request.post('technicien.tarif.get', {
                id_dossier: this.idDossier
            })
            this.tarifs = response.data.posts.post
        },

        async getDevisLines(reset = true) {
            if (reset) {
                this.devisLines = []
            }
            let response = await this.$request.post('technicien.get-devis-lines', {
                id_dossier: this.idDossier
            })            
            
            this.devisLines = response.data.posts.post.lines
            this.devisLinesDefault = JSON.parse(JSON.stringify(this.devisLines))
            this.coeffStaMin = response.data.posts.post.coeff_min
            this.$forceUpdate()
        },

        async updateRemise(line, value) {
            let defaultLine = this.devisLinesDefault.find((item) => item.id == line.id)
            
            if (defaultLine && defaultLine.remise != value) {
                defaultLine.remise = value
                let response = await this.$request.post('technicien.edit-remise-piece', {
                    id_piece: line.id,
                    remise: value
                })
                line.total_ht = response.data.posts.post.total_ht
                line.prix_vente_remise = response.data.posts.post.prix_vente_remise
                this.getTarifAmount()
            }
        },

        async updateQtt(line, value) {
            let defaultLine = this.devisLinesDefault.find((item) => item.id == line.id)
            
            if (defaultLine && defaultLine.qty != value) {
                try {
                    clearTimeout(this.timerEditQtt)
                    this.timerEditQtt = setTimeout(async () => {
                        let response = await this.$request.post('technicien.edit-qtt-piece', {
                            id_piece: line.id,
                            qtt: value,
                            reference: line.reference
                        })
                        defaultLine.qty = value
                        line.total_ht = response.data.posts.post
                        this.getTarifAmount()
                        this.getDevisLines(false)
                    }, 600)
                } catch (error) {
                    console.log(error);
                    
                }
            }
        },

        async updateFacturable(line, value, lineIndex = null) {
            let defaultLine = this.devisLinesDefault.find((item) => item.id == line.id)
            
            if (defaultLine && defaultLine.facturable != value) {
                defaultLine.remise = value
                let response = await this.$request.post('technicien.edit-facturable-piece', {
                    id_piece: line.id,
                    facturable: value
                })
                if (lineIndex) {
                    this.devisLines[lineIndex] = response.data.posts.post
                }
                defaultLine.facturable = value
                this.getTarifAmount()
                this.getDevisLines(false)
            }
        },

        async deleteLine() {
            this.loadingDelete = true
            let response = await this.$request.post('technicien.delete-piece', {
                id_piece: this.selectedLineDelete.id,
                id_dossier: this.idDossier
            })
            this.loadingDelete = false
            if (response.data.posts.post == 1) {
                this.$modal.hide('modal-confirm-delete-line')
                this.getDevisLines(false)
                this.getTarifAmount()
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async makeInvoice(type) {            
            if (type == 'devis' && !this.canEditDevis) {
                this.$toast.error(this.$tt('Total TTC inférieur au montant autorisé'))
                return
            }
            if (this.devisLines.find((item) => item.edit == true)) {
                this.$toast.error(this.$tt("Une ligne de pièce n'est pas sauvegardée"))
                return
            }
            if (!this.data.commentaire_tech || this.data.commentaire_tech.length <= 5) {
                this.$toast.error(this.$tt('Le champ "Travaux effectués" est incomplet'))
                return
            }
            if (this.devisLines.length <= 0 && this.totalTemps.invoice == '00h00') {
                this.$toast.error(this.$tt('Le dossier doit contenir au moins une pièce ou du temps de main d\'oeuvre'))
                return
            }
            this.loadingMakeInvoice = true
            let response = await this.$request.post('technicien.build-devis-pdf', {
                id_dossier: this.idDossier,
                type: type,
            })
            if (response.data.posts.post) {
                this.emailEnvoieClient = this.data.end_customer.mail
                this.generatedDevisUrl = response.data.posts.post
                if (type == 'devis') {
                    this.$toast.success(this.$tt('Devis généré'))
                    await this.saveAndClose()
                    this.$emit('refresh-list')
                } else {
                    this.$toast.success(this.$tt('Facture générée'))
                    this.$modal.hide('modal-tech-devis')
                    this.$emit('refresh-list')
                }
                this.$modal.show('modal-end-dossier')
            }
            this.loadingMakeInvoice = false
        },

        async sendFacture(type) {
            if (!this.$validation.check([
                {value: this.emailEnvoieClient, rules: 'required|email', id: 'inp-email-envoie-facture'},
            ])) {return}

            this.loadingSendFactureEmail = true
            let response = await this.$request.post('technicien.send-facture', {
                email_envoie: this.emailEnvoieClient,
                id_dossier: this.idDossier,
                type: type,
                devis_url: this.generatedDevisUrl
            })
            if (response.data.posts.post == 1) {
                this.$toast.success(this.$tt('Email envoyé au client'))
                this.$modal.hide('modal-end-dossier')
            }
            this.loadingSendFactureEmail = false
        },

        async editPret() {
            await this.$request.post('technicien.edit-pret', {
                id_dossier: this.idDossier,
                pret: this.data.pret ? 1 : 0
            })
            this.getTarifAmount()
        },

        async getMessages() {
            this.defaultMessages = null
            if (this.data.code_reparation) {
                this.loadingGetMessages = true
                let response = await this.$request.post('technicien.get-default-messages', {
                    codification: this.data.code_reparation
                })
                this.loadingGetMessages = false
                this.defaultMessages = response.data.posts.post.map((item) => ({...item, confirmDelete: false}))
            } else {
                this.defaultMessages = []
            }
        },

        selectDefaultMessage(msg) {
            this.data.commentaire_tech = msg.message
            this.$modal.hide('modal-default-messages')
        },

        async addNewDefaultMessage() {
            if (!this.$validation.check([
                {value: this.titleNewDefaultMessage, rules: 'required', id: 'inp-title-default-message'},
            ])) {return}
            this.loadingDefaultNewMessage = true
            let response = await this.$request.post('technicien.add-default-message', {
                titre: this.titleNewDefaultMessage,
                message: this.data.commentaire_tech,
                codification: this.data.code_reparation
            })
            this.selectDefaultMessage(response.data.posts.post)
            this.getMessages()
            this.defaultNewMessageInp = null
            this.loadingDefaultNewMessage = false
            this.titleNewDefaultMessage = null
            this.$modal.hide('modal-save-default-message')
        },

        async deleteDefaultMessage(msg) {
            await this.$request.post('technicien.delete-default-message', {
                message_id: msg.id
            })
            this.getMessages()
        },

        async getCoeff(line) {
            if (line.prix_ha_net) {
                let response = await this.$request.post('technicien.get-coeff-amount', {
                    montant: line.prix_ha_net,
                    id_dossier: this.idDossier
                })
                this.coeffNewLine = response.data.posts.post.value
                
                this.calculEditAmount()
            }
        },

        calculEditAmount() {
            if (this.newLine.prix_ha_net) {
                let coeffValue = this.newLine.facturable == -1 ? this.coeffNewLine : 1
    
                this.newLine.prix_vente = this.newLine.prix_ha_net * coeffValue
                this.newLine.prix_vente_remise = this.newLine.prix_vente - (this.newLine.prix_vente * (this.newLine.remise / 100))
                this.newLine.total_ht = this.newLine.prix_vente_remise * this.newLine.qty
            }
        },

        async saveEditPiece() {
            if (this.coeffState(this.newLine) == 'bg-red-500') {
                this.$eventHub.$emit('clear-input-error')
                this.$eventHub.$emit('validation-input-error', {
                    id: 'inp-remise-saisie-libre',
                    error: this.$tt('Remise trop importante')
                })
                return
            }
            if (!this.newLine.reference || !this.newLine.designation || !this.newLine.prix_ha_net || !this.newLine.prix_vente_remise || !this.newLine.qty || !this.newLine.total_ht || !this.newLine.facturable) {
                this.$toast.error(this.$tt('Merci de saisir tout les champs'))
                return
            }
            if (this.newLine.qty <= 0) {
                this.$toast.error(this.$tt('La quantité doit être supérieur à 0'))
                return
            }
            let endData = this.newLine
            endData.id_dossier = this.idDossier
            this.loadingAddSaisieLibre = true
            await this.$request.post('technicien.edit-piece-devis', endData)
            await this.getTarifAmount()
            await this.getDevisLines(false)
            this.$modal.hide('modal-add-devis-line')
            this.loadingAddSaisieLibre = false
            this.newLine = {}
            this.coeffNewLine = 1
        },

        coeffState(piece) {
            if (piece.facturable != -1) {
                return 'bg-green-500'
            }

            let prixHa = piece.prix_ha_net ? piece.prix_ha_net : piece.prix_ha
            let prixMinRequired = prixHa * this.coeffStaMin
            
            if (piece.prix_vente_remise < prixHa) {
                return 'bg-red-500'
            }
            if (piece.prix_vente_remise <= prixMinRequired) {
                return 'bg-yellow-500'
            }
            if (piece.prix_vente_remise > prixMinRequired) {
                return 'bg-green-500'
            }
        },

        async saveAndClose() {
            this.loadingSave = true
            await this.$request.post('technicien.set-dossier-avancement', {
                'id_dossier': this.idDossier
            })
            this.loadingSave = false
            this.$modal.hide('modal-tech-devis')
            this.$emit('refresh-list')
        },

        async removeGarantie() {
            let newClientRequired = !this.data.end_customer || this.data.end_customer.id == 0
            if (newClientRequired) {
                if (!this.$validation.check([
                    {value: this.formGarantieClient.nom, rules: 'required', id: 'inp-change-nom'},
                    {value: this.formGarantieClient.email, rules: 'required|email', id: 'inp-change-email'},
                    {value: this.formGarantieClient.cp, rules: 'required|cp', id: 'inp-change-cp'},
                    {value: this.formGarantieClient.ville, rules: 'required', id: 'inp-change-ville'},
                ])) {
                    this.$toast.error(this.$tt('Formulaire incomplet'))
                    return
                }
                if (!this.formGarantieClient.confirm_optin) {
                    this.$toast.error(this.$tt('Valider le consentement du client'))
                    return
                }
            }

            this.loadingRemoveGarantie = true
            let endData = {
                id_dossier: this.idDossier
            }
            if (newClientRequired) {
                endData.new_client = JSON.stringify(this.formGarantieClient)
            }
            let response = await this.$request.post('technicien.remove-folder-garantie', endData)
            if (response.data.posts.post == 1) {
                this.$modal.hide('modal-confirm-change-garantie')
                this.init()
            }
            this.$emit('refresh-list')
            this.loadingRemoveGarantie = false
        },

        initNewLineForm() {
            this.newLine = {
                reference: '',
                designation: '',
                int_etat: -1,
                prix_vente: '',
                remise: 0,
                prix_vente_remise: '',
                qty: 1,
                total_ht: '',
                facturable: this.isGarantie ? '1' : '-1',
            }
            this.$modal.show('modal-add-devis-line')
        },

        async sendComplementMsg() {
            if (!this.$validation.check([
                {value: this.emailComplement, rules: 'required', id: 'inp-email-complement'},
                {value: this.msgComplement, rules: 'required', id: 'inp-msg-complement'},
            ])) {return}

            this.loadingSendComplement = true
            let response = await this.$request.post('technicien.send-complement-msg', {
                id_dossier: this.idDossier,
                email: this.emailComplement,
                message: this.msgComplement,
                q_files: JSON.stringify(this.filesComplement)
            })
            this.loadingSendComplement = false
            if (response.data.posts.post == 1) {
                this.emailComplement = null
                this.msgComplement = null
                this.$toast.success(this.$tt('Email envoyé'))
                this.$emit('refresh-list')
                this.$modal.hide('modal-demande-complement-msg')
                this.$modal.hide('modal-tech-devis')
            }
        },

        manageFacturableRemise(line) {
            if (line.facturable != -1) {
                line.remise = 0
            }
        },

        makeDecrementQtt(line) {
            let array = [];
            for (let i = line.qty; i >= 0; i--) {
                array.push(i);
            }
            return array
        },

        openInpPhoto() {
            document.getElementById("fileUpload").click()
        },

        async uploadPhoto(event) {
            let fileInput = event.target.files.length > 0 ? event.target.files[0] : null

            if (fileInput) {
                this.fileUrl = null
                this.loadingAddPhoto = true
                let responseFile = await apiUploadService.post({
                    files: fileInput
                })
                let url = responseFile.data.files[0].url
                await this.$request.post('technicien.add-app-photo', {
                    id_dossier: this.data.id_dossiersav,
                    url: url
                })
                this.loadingAddPhoto = false
                this.getDossierImgs()
                this.$forceUpdate()
            }
        },
        async getDossierImgs() {
            let response = await this.$request.post('technicien.get-dossier-imgs', {
                id_dossier: this.idDossier
            })
            this.data.app_imgs = response.data.posts.post
        },
        async deleteImg(img) {
            await this.$request.post('technicien.delete-app-photo', {
                file_id: img.id
            })
            this.getDossierImgs()
        },
        async sendChauffe() {
            this.loadingChauffe = true
            await this.$request.post('technicien.set-chauffe', {
                id_dossier: this.idDossier
            })
            this.loadingChauffe = false
            this.$emit('refresh-list')
            this.$modal.hide('modal-confirm-chauffe')
            this.$modal.hide('modal-tech-devis')
        },

        trancheRemises(line) {
            let allValues = [
                0.00, 5.00, 10.00, 15.00, 20.00, 25.00, 30.00, 35.00, 40.00, 45.00,
                50.00, 55.00, 60.00, 65.00, 70.00, 75.00, 80.00, 85.00, 90.00, 95.00,
                100.00
            ]
            let tranches = []
            for (const tranche of allValues) {
                if (line.facturable == -1) {
                    let prix_vente_remise = line.prix_vente - (line.prix_vente * (tranche / 100))
                    let prixHa = line.prix_ha_net ? line.prix_ha_net : line.prix_ha
                    if (prix_vente_remise < prixHa) {
                        break
                    }
                }
                tranches.push(tranche)
            }

            return tranches
        },

        async endDossier() {
            this.loadingEndDossier = true
            await this.$request.post('technicien.end-dossier', {
                id_dossier:this.idDossier
            })
            this.$modal.hide('modal-tech-devis')
            this.$emit('refresh-list')
            this.loadingEndDossier = false
        },

        emitInfoFolder() {
            window.parent.postMessage({from: 'reparateur', type: 'info-devis-attente', meta: {line: {
                ref_dossier: this.data.ref_dossier
            }}}, "*");
        },
    },
    mounted () {
        this.init()
    },
}
</script>

<style lang="scss" scoped>

</style>