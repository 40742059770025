<template>
    <div>
        <div class="table-atelier">
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500" :class="tableClass">
                    <thead class="text-xs text-gray-700 uppercase">
                        <tr>
                            <th scope="col" class="px-2 py-2" style="width: 70px">{{ $tt('Action') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Dossier') }}</th>
                            <th scope="col" class="px-2 py-2" v-if="admin">{{ $tt('Intervenant') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt("Donneur d'ordre") }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Demande du') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Marque') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Modèle') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Garantie') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Stockage') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Client') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Tel client') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('IAD') }}</th>
                            <th scope="col" class="px-2 py-2">
                                <div class="flex items-center gap-2">
                                    <tooltip :msg="$tt('Non conforme')" custom="text-slate-800" />{{ $tt('NC') }}
                                </div>
                            </th>
                            <th v-if="type == 'en_cours'" scope="col" class="px-2 py-2">
                                <div class="flex items-center gap-2">
                                    <tooltip :msg="$tt('Pièce reçue')" custom="text-slate-800" />{{ $tt('PR') }}
                                </div>
                            </th>
                            <th v-if="type == 'en_cours'" scope="col" class="px-2 py-2">
                                <div class="flex items-center gap-2">
                                    <tooltip :msg="$tt('Devis à effectuer')" custom="text-slate-800" />{{ $tt('DAE') }}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody :style="'height: ' + (lines && lines.length < 10 ? lines.length * 37 : 350) + 'px'">
                        <tr v-for="(line, lineIndex) in lines" :key="'line-' + lineIndex" class="bg-white hover:bg-blue-50 duration-200 border-b">
                            <!-- Actions -->
                            <td class="px-2 py-1 text-xs" style="width: 70px"><slot name="action" :line="line"></slot></td>
                            <!-- Dossier -->
                            <td class="px-2 py-1 text-xs">{{line.ref_dossier}}</td>
                            <!-- Intervenant -->
                            <td class="px-2 py-1 text-xs" v-if="admin">{{ line.technicien?.nom || '-' }}</td>
                            <!-- Donneur d'ordre -->
                            <td class="px-2 py-1 text-xs">{{ line.nom_do }}</td>
                            <!-- Demande du -->
                            <td class="px-2 py-1 text-xs">{{line.date_create | momentDate}}</td>
                            <!-- Marque -->
                            <td class="px-2 py-1 text-xs">{{line.device['Marque']}}</td>
                            <!-- Modèle -->
                            <td class="px-2 py-1 text-xs">{{line.device['Modele']}}</td>
                            <!-- Garantie -->
                            <td class="px-2 py-1 text-xs">{{line.garantie_four ? line.garantie_four?.libelle : garantieLabels[line.garantie] }}</td>
                            <!-- Stockage -->
                            <td class="px-2 py-1 text-xs">{{ line.device.emplacement }}</td>
                            <!-- Client -->
                            <td class="px-2 py-1 text-xs">
                                <template v-if="line.end_customer && line.end_customer.id != 0">
                                    {{line?.end_customer.nom || '-'}} <br v-if="line.end_customer && line.end_customer.nom && line.end_customer.prenom" /> {{line?.end_customer.prenom}}
                                </template>
                                <template v-else>-</template>
                            </td>
                            <!-- Téléphone client -->
                            <td class="px-2 py-1 text-xs">{{line.end_customer.gsm && line.end_customer.gsm != 'null' ? line.end_customer.gsm : null}} <br v-if="line.end_customer.gsm && line.end_customer.gsm != 'null' && line.end_customer.tel && line.end_customer.tel != 'null'" /> {{line.end_customer.tel && line.end_customer.tel != 'null' ? line.end_customer.tel : ''}}</td>
                            <!-- IAD -->
                            <td class="px-2 py-1 text-xs">
                                <button v-if="line.iad" class="bg-green-500 text-white px-2 py-1 rounded"><i class="fas fa-home mr-0.5"></i> IAD</button>
                            </td>
                            <!-- NC -->
                            <td class="px-2 py-1 text-xs">
                                <div v-if="line.is_passed" class="text-orange-700" :title="line.lib_nc && line.lib_nc != 0 ? line.lib_nc : $tt('Non conforme')"><i class="fas fa-exclamation-triangle" style="font-size: 10px"></i> {{ line.lib_nc && line.lib_nc != 0 ? line.lib_nc : $tt('Non conforme') }}</div>
                            </td>
                            <!-- PR -->
                            <td class="px-2 py-1 text-xs" v-if="type == 'en_cours'"><button v-if="line.pr" class="bg-blue-500 text-white px-2 py-1 rounded">{{ $tt('Pièces reçues') }}</button></td>
                            <!-- DAE -->
                            <td class="px-2 py-1 text-xs" v-if="type == 'en_cours'"><button v-if="line.dae" class="bg-orange-500 text-white px-2 py-1 rounded"><i class="fas fa-exclamation-triangle mr-0.5"></i> {{$tt('Oui')}}</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: {
        lines: {
            default: []
        },
        tableClass: {
            default: ''
        },
        admin: {
            default: false
        },
        type: {}
    },
    computed: {
        garantieLabels() {
            return {
                'garantie_marque': 'Garantie Marque',
                'garantie_distributeur': 'Garantie Distributeur',
                'garantie_pms': 'Garantie PMS',
                'garantie_comp': 'Garantie Complémentaire',
                'hors_garantie': 'Hors garantie',
                '0': '-'
            }
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
}
</script>

<style>
.table-atelier table, .table-atelier tr td {
    border: 1px solid #E4E4E4;
}
.table-atelier th, .table-atelier td {
    width: 120px !important;
    white-space: nowrap; /* Empêche le texte de s'étendre sur plusieurs lignes */
    overflow: hidden; /* Cache le débordement du contenu */
    border: 1px solid #E4E4E4;
    word-wrap: break-word;
}
.table-atelier tbody {
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
}
.table-atelier thead, .table-atelier tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
.table-atelier thead {
    width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
}
</style>