<template>
    <div>
        <app-template>
            <div>
                <div v-if="staIsInit" class="flex gap-1">
                    <div @click="selectedTab = 'station'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'station' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-home mr-1"></i> {{$tt('Informations Station')}}</div>
                    <!-- <div @click="selectedTab = 'categs_client'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'categs_client' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-users mr-1"></i> {{$tt('Types clients')}}</div> -->
                    <div v-if="station && station.iad" @click="selectedTab = 'zone'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'zone' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-location-dot mr-1"></i> {{$tt("Zones d'intervention sur site")}}</div>
                    <div @click="selectedTab = 'categs'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'categs' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-layer-group mr-1"></i> {{$tt('Catégories de produit')}}</div>
                    <div @click="selectedTab = 'tarif_hg'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'tarif_hg' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-euro mr-1"></i> {{$tt('Tarifs hors garantie')}}</div>
                    <div @click="selectedTab = 'agrements'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'agrements' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-industry mr-1"></i> {{$tt('Gestion fournisseurs')}}</div>
                    <div @click="selectedTab = 'coeff'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'coeff' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-calculator mr-1"></i> {{$tt('Coeff Vente')}}</div>
                    <!-- <div @click="selectedTab = 'tarif_sg'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'tarif_sg' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-euro mr-1"></i> {{$tt('Tarifs sous garantie')}}</div> -->
                </div>
                <div class="bg-white p-2 rounded rounded-tl-none border shadow-sm mb-16">
                    <div v-if="station" class="p-4 my-2">
                        <div v-show="selectedTab == 'station'">
                            <reparateur-station-form @refresh-sta="(data) => station = data" />
                        </div>
                        <div v-if="selectedTab == 'categs'">
                            <reparateur-station-categ />
                        </div>
                        <div v-if="selectedTab == 'categs_client'">
                            <reparateur-manage-entite />
                        </div>
                        <div v-if="selectedTab == 'zone'">
                            <reparateur-station-zone />
                        </div>
                        <div v-if="selectedTab == 'tarif_hg'">
                            <reparateur-tarif-hg :iad="station && station.iad" />
                        </div>
                        <div v-if="selectedTab == 'agrements'">
                            <reparateur-agrements :station="station" />
                        </div>
                        <div v-if="selectedTab == 'coeff'">
                            <reparateur-coeff />
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import ReparateurStationForm from './Components/ReparateurStationForm.vue'
import ReparateurStationZone from './Components/ReparateurStationZone.vue'
import ReparateurStationCateg from './Components/ReparateurStationCateg.vue'
import ReparateurTarifHg from './Components/ReparateurTarifHg.vue'
import ReparateurManageEntite from './Components/ReparateurManageEntite.vue'
import ReparateurAgrements from './Components/ReparateurAgrements.vue'
import ReparateurCoeff from './Components/ReparateurCoeff.vue'

export default {
    components: {
        ReparateurStationForm,
        ReparateurStationZone,
        ReparateurStationCateg,
        ReparateurTarifHg,
        ReparateurManageEntite,
        ReparateurAgrements,
        ReparateurCoeff
    },
    data() {
        return {
            selectedTab: 'station',
            station: null,
        }
    },
    computed: {
        staIsInit() {
            if (this.station) {
                if (!this.station.nom || !this.station.tel || !this.station.mail || !this.station.type_societe || !this.station.tva_sta) {
                    return false
                }
                return true
            }
            return null
        }
    },
    methods: {
        async getStation() {
            let response = await this.$request.post('reparateur.get-sta')
            this.station = response.data.posts.post
        }
    },
    mounted () {
        this.getStation()

        // Tracker
        if (location.hostname != "localhost" && location.hostname != "127.0.0.1") {
            window.LogRocket && window.LogRocket.init('omg4ub/districloud-1qc5x');
        }
    },
}
</script>

<style lang="scss" scoped>

</style>