<template>
    <div>
        <modal name="modal-stock-piece" width="98%" height="auto" :scrollable="true">
            <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Stock des pièces') }}</div>
                <button @click="$modal.hide('modal-stock-piece')">
                    <i class="fas fa-times text-red-500 text-xl"></i>
                </button>
            </div>
            <div class="p-4 min-h-[700px] overflow-x-auto">
                <stock-piece :id-dossier="idDossier" @added="$emit('added')" :force-fournisseur="forceFournisseur" />
            </div>
        </modal>
    </div>
</template>

<script>
import StockPiece from './StockPiece.vue'
export default {
    props: {
        idDossier: {
            required: true
        },
        forceFournisseur: {}
    },
    components: {
        StockPiece,
    },
}
</script>