<template>
    <div>

        <div class="container mx-auto flex h-screen items-center justify-center">
            <spinner v-if="type == 0" :size="10" />
            <template v-if="type > 0">
                <div class="p-4 rounded bg-orange-200 text-orange-900 text-center w-[400px]" v-if="type == 1">
                    <div class="font-bold text-xl">Demande déjà traitée</div>
                    <div class="border-t border-orange-900 pt-3 mt-3">
                        <div class="font-bold">{{ claim.valid_at ? 'Validée' : 'Refusée' }}</div>
                        <div>Email: {{ claim.email }}</div>
                        <div v-if="claim.valid_at">Le {{ claim.valid_at | momentDateTime }}</div>
                        <div v-if="claim.refused_at">Le {{ claim.refused_at | momentDateTime }}</div>
                    </div>
                </div>
                <div class="p-4 rounded bg-green-200 text-green-900 font-bold text-xl text-center w-[400px]" v-if="type == 2">
                    Adresse email validée
                </div>
                <div class="p-4 rounded bg-red-200 text-red-900 font-bold text-xl text-center w-[400px]" v-if="type == 3">
                    Adresse email refusée
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            type: 0,
            claim: null
        }
    },
    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async sendRequest() {
            let response = await this.$request.send('user.a2f-accept-email', {
                claim_token: this.$route.query.token,
                valid: this.$route.query.valid,
                no_user: 1
            })
            this.type = response.type
            this.claim = response.claim
        }
    },
    mounted() {
        if (!this.$route.query.token) {
            this.$toast.error('Page invalide')
            return
        }

        this.sendRequest()
    }
}
</script>