import EventHub from '@/eventHub';
import LangTextTool from './../tools/LangTextTool'

function checkRule(rule, value) {
        const rulesTools = {
            required: () => {
                if (!value || value === null || value === '') {
                    return LangTextTool.getText('Champ obligatoire')
                }
            },

            tel: () => {
                var regex = new RegExp(/^(01|02|03|04|05|06|07)[0-9]{8}/gi);
                if (value && !regex.test(value)) {
                    return LangTextTool.getText('Numéro invalide')
                }
            },

            cp: () => {
                var regex = new RegExp(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/i);
                if (value && !regex.test(value)) {
                    return LangTextTool.getText('Code postal invalide')
                }
            },

            siret: () => {
                var regex = new RegExp(/\d{14}/g);
                if (value && !regex.test(value)) {
                    return LangTextTool.getText('Le numéro de siret doit être composé de 14 chiffres, exemple: 12345678900011')
                }
            },

            num: () => {
                if (value && isNaN(value)) {
                    return LangTextTool.getText('Le champ doit être un chiffre')
                }
            },

            min: (nb) => {
                if (value && Number(value) < nb) {
                    return LangTextTool.getText('Minimum: ') + nb
                }
            },

            minLength: (nb) => {
                if (value && value.length < nb) {
                    return LangTextTool.getText('Minimum: ') + nb + ' caractères'
                }
            },

            maxLength: (nb) => {
                if (value && value.length > nb) {
                    return LangTextTool.getText('Maximum: ') + nb + ' caractères'
                }
            },

            email: () => {
                if (value && !String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    return LangTextTool.getText('Email invalide')
                }
            },

            tvaintra: () => {
                var regex = new RegExp(/^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))/);
                if (value && !regex.test(value)) {
                    return LangTextTool.getText('Numéro TVA incorrect')
                }
            },

            iban: () => {
                let isValidIBANNumber = (input) => {
                    var CODE_LENGTHS = {
                        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
                        CH: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
                        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
                        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
                        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
                        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
                        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
                        AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
                        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
                    };
                    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
                            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
                            digits;
                    // check syntax and length
                    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
                        return false;
                    }
                    // rearrange country code and check digits, and convert chars to ints
                    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
                        return letter.charCodeAt(0) - 55;
                    });
                    // final check
                    return mod97(digits) === 1;
                }
                
                let mod97 = (string) => {
                    var checksum = string.slice(0, 2), fragment;
                    for (var offset = 2; offset < string.length; offset += 7) {
                        fragment = String(checksum) + string.substring(offset, offset + 7);
                        checksum = parseInt(fragment, 10) % 97;
                    }
                    return checksum;
                }

                if (value) {
                    let isValid = isValidIBANNumber(value)
                    if (!isValid) {
                        return LangTextTool.getText('IBAN incorrect')
                    }
                }
            }
        }

        let checked = false
        if (rule.includes(':')) {
            checked = rulesTools[rule.split(':')[0]](rule.split(':')[1]) || true
        } else {
            checked = rulesTools[rule]() || true
        }
        return checked
}

function check(items) {
    let errorFound = false

    EventHub.$emit('clear-input-error')

    items.forEach((item) => {
        if (item.rules) {
            const rules = item.rules.split('|')
            rules.forEach((rule) => {
                let checkedRule = checkRule(rule, item.value)
                if (checkedRule !== true) {                    
                    errorFound = true
                    EventHub.$emit('validation-input-error', {
                        id: item.id,
                        error: checkedRule
                    })
                }
            })
        }
    })

    return !errorFound
}

var ValidationTool = {
    check
}

export default ValidationTool
