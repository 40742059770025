// https://distriadmin.savlog.net/auth?token={token}&id={id}&next={routeName}

export default [
    // Distriboard
    'distriboard.make-avoir',
    'distriboard.list-avoir',

    // Base de connaissance
    'bdc.list',
    'bdc.tchat',

    // Admin
    'inscription.claim',
    'apps.list',
    'procedure',
    'manage-categories',
    'client.admin.liaisons',
    'client.admin.entite',
    'client.admin.users',
    'client.admin.pricing-recap',

    'transporteur.edit',
    'selfcare.edit',
    'client.admin.facturation',
    'welcome.settings',

    // Reparateur
    'reparateur.conf',
    'reparateur.facturation',
    'reparateur.stock',
    'reparateur.commande-stock',

    // Ecosystem
    'ecosystem.folders',
    'ecosystem.categories',
    'ecosystem.connect',
    'ecosystem.catalog',
    'ecosystem.paiement-history',
    'ecosystem.send-folder'
]
