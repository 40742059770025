<template>
    <div>
        <div v-if="displayPage" class="container mx-auto my-10 text-center">
            <template v-if="validAccount">
                <div class="text-green-600">
                    <i class="fas fa-unlock text-6xl"></i>
                    <div class="text-2xl font-bold mt-4">Appareil enregistré</div>
                    <p class="font-light text-lg mt-4 w-[400px] mx-auto">
                        Votre appareil est maintenant enregistré sur la plateforme Districloud, merci de votre compréhension.
                    </p>
                </div>
                <div class="mt-10">
                    <a :href="$route.query.redirect ? $route.query.redirect : 'https://www.districloud.net'" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded">Retourner sur Districloud <i class="fas fa-share ml-2"></i></a>
                </div>
            </template>
            <template v-if="!validAccount">
                <i class="fas fa-lock text-6xl"></i>
                <div class="text-2xl font-bold mt-4">Accès restreint</div>
                <p class="font-light text-lg mt-4 w-[400px] mx-auto">
                    Un code d'accès vous a été envoyé à l'adresse email :
                    <br /><b class="font-bold">{{ data.email }}</b>
                </p>
                <div class="flex justify-center my-10">
                    <CodeInput
                    :loading="loadingCheckCode"
                    class="input"
                    v-on:complete="onComplete"
                    :auto-focus="true"
                    />
                </div>
                <button :disabled="loadingNewCode" @click="sendNewCode" class="text-blue-500 hover:underline">
                    <spinner :size="10" v-if="loadingNewCode" />
                    <template v-if="!loadingNewCode">
                        Renvoyer un nouveau code
                    </template>
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";

export default {
    components: {
        CodeInput
    },
    data() {
        return {
            data: null,
            displayPage: false,
            code: null,
            validAccount: false,
            loadingCheckCode: false,
            loadingNewCode: false,
        }
    },
    methods: {
        async getAuth() {
            this.data = await this.$request.send('user.get-a2f', {
                url_token: this.$route.query.token,
                no_user: 1
            })
            if (this.data.email) {
                this.displayPage = true
            } else {
                this.$toast.error('Page invalide')
            }
        },
        
        onComplete(v) {
            this.code = v
            this.sendCode()
        },

        async sendCode() {
            this.loadingCheckCode = true
            let response = await this.$request.post('user.valid-a2f', {
                code: this.code,
                no_user: 1
            })
            this.loadingCheckCode = false
            if (response.data.posts.post == 1) {
                this.validAccount = true
            } else {
                this.$toast.error(response.data.posts.error)
            }
        },

        async sendNewCode() {
            this.loadingNewCode = true
            let response = await this.$request.post('user.resend-a2f', {
                url_token: this.$route.query.token,
                no_user: 1
            })
            this.loadingNewCode = false
            if (response.data.posts.post) {
                this.$toast.success('Email envoyé')
            }
        }
    },
    mounted() {
        if (!this.$route.query.token) {
            this.$toast.error('Page invalide')
        }
        this.getAuth()
    }
}
</script>