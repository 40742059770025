<template>
    <div>
        <modal name="modal-contact" height="auto" width="70%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-xl">
                    <h2>{{ $tt('Contacter un expert') }}</h2>
                    <button @click="$modal.hide('modal-contact')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <h2 class="text-lg font-bold mb-4 pb-2 border-b"><i class="fas fa-phone mr-1"></i> {{$tt('Coordonnées')}}</h2>
                            <div>{{$tt('Tel')}}: +33 6 75 59 58 41</div>
                            <div>{{$tt('Mail')}}: contact@savlog.net</div>
                        </div>
                        <div>
                            <h2 class="text-lg font-bold mb-4 pb-2 border-b"><i class="fas fa-comments mr-1"></i> {{$tt('Envoyer un message')}}</h2>
                            <input-rule v-model="contact.message" type="textarea" :options="{rows: 10}" :label="$tt('Message')" :label-inline="false" class="mt-4" id="contact-message" />
                            <button :disabled="loadingSendContact" @click="sendContactEmail" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white text-sm rounded mt-4">
                                <spinner v-if="loadingSendContact" :size="10" />
                                <template v-if="!loadingSendContact">
                                    {{$tt('Envoyer')}} <i class="fas fa-paper-plane ml-1"></i>
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-remove-connexion" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-xl">
                    <h2>{{ $tt('Confirmer la suppression') }}</h2>
                    <button @click="$modal.hide('modal-confirm-remove-connexion')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div v-if="selectedRemoveConnexion">
                    <div>{{ $tt('Souhaitez-vous réellement supprimer la connexion avec ' + selectedRemoveConnexion.nom) }} ?</div>
                    <div class="flex items-center gap-4 mt-4">
                        <button class="px-4 py-2 bg-slate-300 hover:bg-slate-400 duration-200 rounded text-sm" @click="$modal.hide('modal-confirm-remove-connexion')">{{ $tt('Annuler') }}</button>
                        <button :disabled="loadingRemoveConnexion" class="px-4 py-2 bg-red-600 hover:bg-red-700 duration-200 rounded text-sm text-white" @click="removeNegoLiaison">
                            <spinner v-if="loadingRemoveConnexion" :size="10" />
                            <template v-if="!loadingRemoveConnexion">
                                <i class="fas fa-trash mr-1"></i> {{ $tt('Supprimer') }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>


        <div class="p-4" v-if="liaisons.length <= 0">
            <spinner />
        </div>
        <div v-if="liaisons.length > 0 && client">
            <div class="grid xl:grid-cols-10 gap-4 bg-slate-100 p-4 rounded">
                <div class="xl:col-span-3">
                    <div class="bg-white rounded p-4 border-2 border-slate-200">
                        <h2 class="text-xl font-bold mb-4 pb-4 border-b">{{ $tt('1. Choisir vos distributeurs') }}</h2>
                        <input-rule v-model="selectedCountry" type="vselect" :options="{values: countries}" :label="$tt('Pays de la centrale d\'achat')" :label-inline="false" class="mb-4" />
                        <template v-if="selectedCountry">
                            <input-rule v-model="filters.nom" type="text" class="mb-4" :label="$tt('Nom de la centrale')" :label-inline="false" />
                            <div class="max-h-[500px] overflow-y-auto">
                                <template v-for="(liaison, liaisonIndex) in liaisons.filter((item) => item.pays == selectedCountry.toLowerCase())">
                                    <div class="border-b p-1 grid grid-cols-5 items-center" :key="'liaison-' + liaisonIndex" v-if="!savedLiaions.find((item) => item.id == liaison.id)">
                                        <div class="col-span-3">{{ liaison.nom }}</div>
                                        <!-- <button v-if="liaison.connexion == 'claim'" class="px-4 py-2 rounded text-xs bg-red-200 hover:bg-red-300 mt-2 duration-200" @click="removeLiaison(liaison)">{{ $tt('Retirer') }} <i class="fas fa-times ml-1"></i></button> -->
                                        <!-- <button v-if="liaison.connexion && liaison.connexion != 'claim'" class="px-4 py-2 rounded text-xs bg-slate-200 hover:bg-slate-300 mt-2 duration-200" @click="removeLiaison(liaison)">{{ $tt('Accès de base') }}</button> -->
                                        <div class="col-span-2 text-right">
                                            <button v-if="client.prix_nego <= 0 && !savedLiaions.find((item) => item.id == liaison.id)" class="px-4 py-2 rounded text-xs bg-green-500 hover:bg-green-600 text-white mt-2 duration-200" @click="addLiaison(liaison)">
                                                {{ $tt('Se connecter') }}
                                            </button>
                                            <button v-if="client.prix_nego > 0 && !savedLiaions.find((item) => item.id == liaison.id)" class="px-4 py-2 rounded text-xs bg-green-500 hover:bg-green-600 text-white mt-2 duration-200" @click="addNegoLiaison(liaison)" :disabled="loadingAddNego == liaison.id">
                                                <spinner v-if="loadingAddNego == liaison.id" :size="10" />
                                                <template v-if="loadingAddNego != liaison.id">
                                                    {{ $tt('Se connecter') }}
                                                </template>
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="bg-white rounded p-4 xl:col-span-4 border-2 border-slate-200">
                    <h2 class="text-xl font-bold mb-4 pb-4 border-b">{{ $tt('2. Etats de vos connexions actuelles') }}</h2>
                    <div class="max-h-[600px] overflow-y-auto">
                        <div
                        class="border-b p-2 flex items-center justify-between"
                        v-for="(liaison, liaisonIndex) in savedLiaions"
                        :key="'liaison-connected-' + liaisonIndex"
                        >
                            <div>
                                <div class="font-bold">{{ liaison.nom }}</div>
                                <div class="text-xs font-light" v-if="liaison.premium && client && client.prix_nego">{{ $tt('Connexion incluse dans votre abonnement') }}</div>
                                <div class="text-xs font-light" v-if="liaison.premium && liaison.connexion && liaison.connexion.grossiste_pay && client.prix_nego == 0">
                                    <div v-if="liaison.connexion.prix <= 0">{{ $tt('Connexion offerte') }}</div>
                                    <div v-if="liaison.connexion.prix > 0">{{$tt('Coût mensuel')}}: {{ liaison.connexion.prix }} €</div>
                                </div>
                                <!-- Badge -->
                                <div class="flex items-center gap-2 mt-1">
                                    <div class="px-2 py-1 bg-blue-200 rounded text-xs" v-if="liaison.connexion && !savedLiaions[liaisonIndex].premium && liaison.connexion?.validation == 0"><i class="fas fa-check mr-0.5"></i> {{ $tt('Gratuite') }}</div>
                                    <div class="px-2 py-1 bg-yellow-200 rounded text-xs" v-if="!liaison.connexion || (liaison.connexion && savedLiaions[liaisonIndex].premium) || liaison.connexion?.validation == 1"><i class="fas fa-star mr-0.5"></i> {{ $tt('Premium') }}</div>
                                    <div class="px-2 py-1 bg-slate-200 rounded text-xs"><i class="fa-solid fa-earth-europe mr-0.5"></i> {{ liaison.pays }}</div>
                                    <div class="px-2 py-1 bg-slate-200 rounded text-xs" v-if="liaison.connexion?.validation == 1"><i class="fas fa-hourglass-half mr-0.5"></i> {{ $tt('En attente de validation') }}</div>
                                    <div class="px-2 py-1 bg-slate-200 rounded text-xs" v-if="liaison.connexion?.grossiste_pay == 1 && !liaison.is_big"><i class="fas fa-folder mr-0.5"></i> {{ liaison.connexion.nb_dossier }} {{ $tt('Dossiers') }}</div>
                                </div>
                            </div>
                            <div class="flex items-center gap-2">
                                <input-rule
                                class="max-w-[120px]"
                                v-model="liaison.nb_dossier"
                                v-if="liaison.is_big && liaison.premium && (!liaison.liaison || !liaison.liaison.grossiste_pay) && client.prix_nego == 0"
                                type="number"
                                :label="$tt('Nb dossiers / mois')"
                                :label-inline="false"
                                @input="() => pricing = null"
                                />
                                <!-- Button -->
                                <div>
                                    <div class="px-2 py-1 bg-red-200 hover:bg-red-300 duration-200 rounded text-xs cursor-pointer" v-if="liaison.connexion && savedLiaions[liaisonIndex].premium && !liaison.connexion?.validation && liaison.connexion.admin_central" @click="switchPremium(liaisonIndex, false)"><i class="fas fa-repeat mr-0.5"></i> {{ $tt("Offre gratuite") }}</div>
                                    <div class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 rounded text-xs cursor-pointer text-white" v-if="liaison.connexion && !savedLiaions[liaisonIndex].premium && liaison.connexion?.validation == 0" @click="switchPremium(liaisonIndex, true)"><i class="fas fa-star mr-0.5"></i> {{ $tt('Convertir en offre premium') }}</div>
                                    <div class="px-2 py-1 bg-red-200 hover:bg-red-300 duration-200 rounded text-xs cursor-pointer" v-if="!liaison.connexion" @click="removeLiaison(liaison); $forceUpdate()"><i class="fas fa-times mr-0.5"></i> {{ $tt('Retirer') }}</div>
                                    <div v-if="client.prix_nego > 0 && liaison.connexion.grossiste_pay" @click="selectedRemoveConnexion = liaison; $modal.show('modal-confirm-remove-connexion')" class="cursor-pointer"><i class="fas fa-trash text-red-500"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="xl:col-span-3">
                    <div v-if="client.prix_nego == 0" class="bg-white border-2 border-slate-200 p-4 mb-4">
                        <h1 class="text-xl font-bold mb-4 pb-4 border-b">{{ $tt('3. Récapitulatif') }}</h1>
                        <div class="mt-4">
                            <div class="text-center">
                                <button :disabled="loadingButton" v-if="!pricing" @click="priceSimulate" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-white">
                                    <spinner v-if="loadingButton" :size="10" />
                                    <template v-if="!loadingButton">
                                        <div class="font-bold text-lg">{{$tt('Valider')}}</div>
                                        <div class="font-light text-sm">{{$tt('Calculer votre nouveau tarif')}}</div>
                                    </template>
                                </button>
                            </div>
                            <div v-if="pricing" class="bg-green-100 border-2 border-green-400 p-4 rounded">
                                <div>
                                    <div class="text-lg font-bold mb-2">{{$tt('Nouveau montant mensuel')}}: {{ pricing }} €</div>
                                    <div v-if="pricingProd" class="text-lg font-bold mb-2">{{$tt('Frais mise en production')}}: {{ pricingProd }} €</div>
                                    <spinner v-if="loadingButton" :size="10" />
                                    <template v-if="!loadingButton">
                                        <div>
                                            <button @click="save" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-white text-sm">
                                                <i class="fas fa-check mr-1"></i> {{$tt('Valider la configuration')}}
                                            </button>
                                        </div>
                                        <div>
                                            <button @click="$modal.show('modal-contact')" class="px-4 py-2 bg-slate-300 hover:bg-slate-400 text-sm duration-200 rounded mt-2">
                                                <i class="fas fa-comments mr-1.5"></i> {{$tt('Demander à être recontacté')}}
                                            </button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="initPrice > 0" class="bg-white border-2 border-slate-200 p-4">
                        <h2 class="text-xl font-bold mb-4 pb-4 border-b">{{ $tt('Coûts (HT)') }}</h2>
                        <div class="grid grid-cols-2 gap-4">
                            <div class="border-r p-4">
                                <div>Mensuels</div>
                                <div class="font-bold text-xl">{{ initPrice }} €</div>
                            </div>
                            <div class="p-4">
                                <div>Mise en production</div>
                                <div class="font-bold text-xl">{{ prodPrice }} €</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    watch: {
        filters: {
            deep: true,
            handler() {
                this.filterLines()
            }
        },
        savedLiaions: {
            deep: true,
            handler() {
                this.checkNewConf()
            }
        }
    },
    data() {
        return {
            requestData: [],
            liaisons: [],
            filters: {},
            countries: [],
            selectedCountry: 'France',
            savedLiaions: [],

            defaultConf: null,
            pricing: null,
            pricingProd: null,
            initPrice: null,
            prodPrice: null,
            contact: {},

            selectedRemoveConnexion: null,

            loadingButton: false,
            loadingSendContact: false,
            loadingAddNego: false,
            loadingRemoveConnexion: false,
        }
    },
    methods: {
        async getCentrales() {
            let response = await this.$request.post('grossiste.get-catalog')
            this.requestData = response.data.posts.post.catalog
            this.client = response.data.posts.post.client
            this.liaisons = this.requestData

            // Get countries list
            const pluck = (arr, key) => arr.map(item => item[key]);
            let countries = pluck(this.liaisons, 'pays')
            let onlyUnique = (value, index, array) => {
                return array.indexOf(value) === index;
            }
            countries = countries.filter(onlyUnique);
            countries = countries.map((country) => {return country.charAt(0).toUpperCase() + country.slice(1)}).sort()
            this.countries = countries

            this.savedLiaions = []
            this.liaisons.forEach((item) => {
                if (item.connexion) {
                    item.premium = (item.connexion.actif && item.connexion.grossiste_pay) || item.connexion.validation ? true : false
                    if (item.premium || item.connexion.admin_central) {
                        item.nb_dossier = item.connexion.nb_dossier_estimation
                        this.savedLiaions.push(item)
                    }
                }
            })
            this.sortLiaisons()
        },
        
        async getInitPrice() {
            let response = await this.$request.post('welcome.calcul-pricing', {
                connexions: JSON.stringify(this.savedLiaions),
                only_price: 1,
            })
            this.initPrice = response.data.posts.post.month
            this.prodPrice = response.data.posts.post.prod
        },

        filterLines() {
            let endObj = []
            this.requestData.forEach((liaison) => {
                if (!liaison.connexion) {
                    if (!this.filters.nom || liaison.nom.toLowerCase().startsWith(this.filters.nom.toLowerCase())) {
                        endObj.push(liaison)
                    }
                }
            })
            this.liaisons = endObj
        },

        addLiaison(liaison) {
            if (!this.savedLiaions.find((l) => l.id == liaison.id)) {
                let item = {...liaison}
                item.premium = true
                item.connexion = null
                this.savedLiaions.push(item)
                this.sortLiaisons()
                this.filters.nom = null
            }
        },

        async addNegoLiaison(liaison) {
            this.loadingAddNego = liaison.id
            let response = await this.$request.send('welcome.add-nego-connexion', {
                id_centrale: liaison.id
            })
            if (response == 1) {
                await this.getCentrales()
            }
            this.loadingAddNego = 0
            this.filters.nom = null
        },

        removeLiaison(liaison) {
            let index = this.savedLiaions.findIndex((item) => item.id == liaison.id)
            if (index >= 0) {
                this.savedLiaions.splice(index, 1)
            }
        },

        sortLiaisons() {
            this.savedLiaions = this.savedLiaions.sort(function(a, b) {
                if (a.nom < b.nom) return -1;
                if (a.nom > b.nom) return 1;
                return 0;
            })
        },

        switchPremium(index, value) {
            this.savedLiaions[index].premium = value
            this.$forceUpdate()
            this.checkNewConf()
        },

        async priceSimulate() {
            let emptyNbDossier = false
            this.savedLiaions.forEach((item) => {
                // Overwrite when client has negociated price
                if (this.client.prix_nego != 0) {
                    item.nb_dossier = 10000
                }
                if (item.is_big && item.premium && !item.nb_dossier) {
                    emptyNbDossier = true
                }
            })
            if (emptyNbDossier) {
                this.$toast.error('Estimation du nombre de dossier incomplet')
                return
            }

            this.loadingButton = true
            let response = await this.$request.post('welcome.calcul-pricing', {
                connexions: JSON.stringify(this.savedLiaions),
                only_price: 1,
                track_mail: 1,
                new_prod_amount: 1,
            })
            if (response.data.posts.post.month == 0) {
                this.$toast.error(this.$tt('Erreur: Toutes vos offres sont gratuites'))
            } else {
                this.pricing = response.data.posts.post.month
                this.pricingProd = response.data.posts.post.prod
            }
            this.loadingButton = false
        },
        async save() {
            this.loadingButton = true
            let response = await this.$request.post('welcome.calcul-pricing', {
                connexions: JSON.stringify(this.savedLiaions),
            })
            this.loadingButton = false
            if (response.data.posts.post.month > 0) {
                await this.getCentrales()
                await this.getInitPrice()
                this.$toast.success(this.$tt('Configuration en attente de validation'))
                this.pricing = null
                this.defaultConf = JSON.parse(JSON.stringify(this.savedLiaions))
                this.$emit('end')
            } else {
                this.$toast.error(this.$tt('Vous ne pouvez pas avoir un montant à 0'))
            }
        },

        checkNewConf() {
            this.pricing = null
            if (!this.defaultConf) {
                this.defaultConf = JSON.parse(JSON.stringify(this.savedLiaions))
            }
            this.$forceUpdate()
        },

        async sendContactEmail() {
            if (!this.$validation.check([
                {value: this.contact.message, rules: 'required', id: 'contact-message'},
            ])) {return}

            this.loadingSendContact = true
            let endObj = {...this.contact}
            endObj.message = endObj.message.replaceAll("\n", "<br/>")
            // this.contact.message = this.contact.message.replaceAll("\n", "<br/>");
            let response = await this.$request.post('welcome.send-contact-mail', endObj)
            if (response.data.posts.post == 1) {
                this.$toast.success(this.$tt('Email envoyé'))
                this.$modal.hide('modal-contact')
                this.contact = {}
            }
            this.loadingSendContact = false
        },

        async removeNegoLiaison() {
            this.loadingRemoveConnexion = true
            let response = await this.$request.send('welcome.remove-nego-connexion', {
                connexion_id: this.selectedRemoveConnexion.connexion.id
            })
            if (response == 1) {
                await this.getCentrales()
                this.$modal.hide('modal-confirm-remove-connexion')
            }
            this.loadingRemoveConnexion = false
        }
    },
    async mounted () {
        await this.getCentrales()
        await this.getInitPrice()
    },
}
</script>

<style lang="scss" scoped>

</style>