<template>
    <div>
        <div v-if="displayPage">
            <div class="fixed top-0 left-0 right-0 flex items-center justify-between px-[15px] py-2 mb-5 bg-gray-100" style="border-bottom: 1px solid #e6e6e6">
                <a href="https://districloud.net"><img src="https://disticloudfiles.net/upload/files/logo_districloud.png" class="h-[39px]" /></a>
                <div class="flex items-center justify-between gap-4">
                    <button @click="changeLang('fr')"><img :class="this.lang == 'fr' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Flag_of_France.png/1280px-Flag_of_France.png" alt=""></button>
                    <button @click="changeLang('en')"><img :class="this.lang == 'en' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Flag_of_the_United_Kingdom.png/1200px-Flag_of_the_United_Kingdom.png" alt=""></button>
                    <button @click="changeLang('de')"><img :class="this.lang == 'de' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/1280px-Flag_of_Germany.svg.png" alt=""></button>
                    <button @click="changeLang('es')"><img :class="this.lang == 'es' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png" alt=""></button>
                </div>
            </div>
            <div class="mt-24 container lg:w-1/2 mx-auto">
                <div class="bg-slate-200 text-slate-600 p-4 rounded mb-4">
                    <div class="font-bold">{{ $tt('Nouveau mot de passe') }}</div>
                    <p class="font-light text-sm mt-1">{{ $tt("Vous êtes sur le point de réinitialiser votre mot de passe. Une fois le mot de passe modifié, tout les appareils liés à ce compte devront utiliser le nouveau mot de passe.") }}</p>
                </div>
                <div class="border mt-5 rounded p-4">
                    <input-rule type="text" v-model="email" :label="$tt('Saisir votre identifiant de connexion')" :label-inline="false" id="inp-email" />
                    <button v-if="!sending" @click="sendNewPassword" class="bg-sky-600 hover:bg-sky-700 duration-200 text-white rounded px-6 text-sm font-light py-2 mt-2">{{ $tt('Réinitialiser le mot de passe') }}</button>
                    <a class="text-xs text-sky-700 font-light hover:underline ml-5" href="/inscription">{{ $tt("Identifiant oublié ?") }}</a>
                    <!-- <a class="text-xs text-sky-700 font-light hover:underline ml-5" href="/inscription">Identifiant oublié ?</a> -->
                    <spinner :size="10" v-if="sending" class="mt-4" />
                </div>
            </div>
            <div class="flex justify-center mt-5">
                    <a class="text-sm text-sky-500 font-light hover:underline" href="https://districloud.net">{{ $tt("Retour à l'accueil") }}</a>
                </div>
            <div class="fixed bottom-0 left-0 right-0 flex items-center justify-between px-[15px] py-2 bg-gray-100">
                <img src="https://www.districloud.net/img/logosavlog.png" class="h-[29px]" />
                <p class="text-sky-500" style="text-align: right; font-size: 10px; margin-right: 10px;" id="copyright">
                    <a href="https://www.districloud.fr" target="_blank"> www.districloud.fr</a>  © Savlog, DistriCloud 1998 - 2023 v2.2.1
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },

    data() {
        return {
            email: null,
            sending: false,
            displayPage: false,
            lang: null,
        }
    },

    methods: {
        async sendNewPassword() {
            if (!this.$validation.check([
                {value: this.email, rules: 'required', id: 'inp-email'},
            ])) {return}
            this.sending = true
            let response = await this.$request.post('inscription.forget-password', {
                email: this.email,
                lang: localStorage.getItem('lang') || 'FR'
            })
            this.sending = false
            if (response.data.posts.post == 1) {
                this.email = null
                this.$toast.success(this.$tt('Un lien vous a été envoyé par email'))
            } else {
                if (response.data.posts.post && !response.data.posts.post.displayError) {   
                    this.$toast.error(this.$tt('Une erreur est survenue'))
                }
            }
        },

        changeLang(lang) {
            this.$lang.setLang(lang)
            location.reload()
        }
    },

    mounted () {
        this.lang = localStorage.getItem('lang') || 'fr';

        let urlString = window.location.href
        let paramString = urlString.split('?')[1]
        let queryString = new URLSearchParams(paramString)
        let endObj = {
            lang: null,
        }

        for (let pair of queryString.entries()) {
            if (pair[0] == 'lang') {
                endObj.lang = pair[1]
            }
        }

        if (endObj.lang) {
            this.lang = endObj.lang
            this.$lang.setLang(endObj.lang)
        }

        this.displayPage = true

        window.LogRocket && window.LogRocket.init('omg4ub/districloud2');
    },
}
</script>

<style lang="scss" scoped>
</style>
